import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// import { getMessaging, getToken } from "firebase/messaging"

// 운영

const firebaseConfig = {
    apiKey: "AIzaSyDPhZtHUSRLLe-l3emkwrLbVeGLpvnP5ro",
    authDomain: "sdacademy-a5ed3.firebaseapp.com",
    projectId: "sdacademy-a5ed3",
    storageBucket: "sdacademy-a5ed3.appspot.com",
    messagingSenderId: "234261485738",
    appId: "1:234261485738:web:16f83dfccc85988c4858d3",
    measurementId: "G-GFDW0ML6CL"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
// export const messaging = getMessaging(app);
// export var token;


// function requestPermission() {
//     console.log("Request Permission...");
//     Notification.requestPermission().then((permission) => {
//         if (permission === "granted") {
//             console.log("Notification permission granted.");
//             const messaging = getMessaging(app);

//             getToken(messaging, {vapidKey: "BIj5lijo8sGmrf_EsdWKTZ5E2fJYduNjj48MvzhQTCLMyBOf7znsPXQwB5_RdGwMEzsrKTtAe6fOvAY8_2ptUgY"})
//                 .then((currentToken) => {
//                     if(currentToken) {
//                         console.log('currentToken: ', currentToken)
//                         token = currentToken;
//                     } else {
//                         console.log('Can not get Token')
//                     }
//                 })
//         } else {
//             console.log("Do not have permission!");
//         }
//     })
// };

// requestPermission();

// const { google } = require('google-auth-library');

// // Define the scope required for FCM
// const SCOPES = ['https://www.googleapis.com/auth/firebase.messaging'];

// // 브라우저 환경에서 사용 가능한 방식으로 경로 지정
// const SERVICE_ACCOUNT_PATH = '/path/to/your/service-account.json';

// async function getAccessToken() {
//   try {
//     // Load the service account key file
//     const response = await fetch(SERVICE_ACCOUNT_PATH);
//     const key = await response.json();

//     // Create a JWT client
//     const jwtClient = new google.auth.JWT(
//       key.client_email,
//       null,
//       key.private_key,
//       SCOPES,
//       null
//     );

//     // Authorize and get tokens
//     const tokens = await jwtClient.authorize();
//     return tokens.access_token; // Return the access token
//   } catch (error) {
//     console.error('Error obtaining access token:', error);
//     throw error;
//   }
// }

// export { getAccessToken };