import React from 'react';
import { db } from '../firebase_config.js';
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import 'react-calendar/dist/Calendar.css';
import AlmMobile from '../images/buttons/alarm_mobile.png';
import Swal from "sweetalert2";

function AdminPage() {
    

    return (
        <div class="wrap1">
            <div class="video1">
                <iframe src="https://www.youtube.com/embed/t3PyxLLZ6Dk?autoplay=1&mute=1&controls=0&loop=1&playlist=t3PyxLLZ6Dk" 
                    title="홈페이지 노출용_CUK 여름" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
                    gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                </iframe>
            </div>
        </div>
    );
}

export default AdminPage;