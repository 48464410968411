import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import BtnClose from '../images/buttons/close.png';
import BtnDown from '../images/buttons/chevron-down.png';

function NewModStudent(props) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const colId = state.colId;
    const appInstallYn = state.appInstall;

    const [studentData, setStudentData] = useState([]);
    const [registerUsername, setRegisterUsername] = useState("");
    const [registerUserphone, setRegisterUserphone] = useState("");
    const [registerParentphone, setRegisterParentphone] = useState("");
    const [registerSchool, setRegisterSchool] = useState("");
    const [registerGrade, setRegisterGrade] = useState("");
    const [registerGender, setRegisterGender] = useState("");
    const [registerSeatno, setRegisterSeatno] = useState("");
    
    const callDb = useCallback(async () => {
        const docRef = doc(db, "users", colId);
        const docSnap = await getDoc(docRef);

        setStudentData(docSnap.data());

        setRegisterUsername(docSnap.data().username);
        setRegisterUserphone(docSnap.data().userphone);
        setRegisterParentphone(docSnap.data().parentphone);
        setRegisterSchool(docSnap.data().school);
        setRegisterGrade(docSnap.data().grade);
        setRegisterGender(docSnap.data().gender);
        setRegisterSeatno(docSnap.data().seatno);
    }, [colId])

    useEffect(() => {
        if(colId !== "new"){
            callDb();
        }
    }, [callDb, colId])

    const saveUserData = async () => {
        if(colId !== "new"){
            const ref = doc(db, "users", colId);

            try{
                await updateDoc(ref, {
                    username: registerUsername,
                    userphone: registerUserphone,
                    parentphone: registerParentphone,
                    school: registerSchool,
                    grade: registerGrade,
                    gender: registerGender,
                    seatno: registerSeatno,
                })
                
                Swal.fire({
                    icon: "success",
                    title: "편집 완료",
                    // text: "",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                        navigate("/studentmng");
                    }
                });
            }catch(error){
                console.log(error)
            }
        }else if(registerUsername !== "" && registerUserphone !== ""){
            const docRef = doc(db, "users", registerUserphone)
            const docSnap = await getDoc(docRef);

            if(docSnap.exists()){
                Swal.fire({
                    icon: "error",
                    title: "중복 수강생데이터 ",
                    text: "해당 휴대전화번호의 수강생이 이미 존재합니다.",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });
            }else{
                try{
                    await setDoc(docRef, {
                        auth: 1,
                        groupid: "sd3",
                        token: "",
                        userid: "",
                        username: registerUsername,
                        userphone: registerUserphone,
                        parentphone: registerParentphone,
                        school: registerSchool,
                        grade: registerGrade,
                        gender: registerGender,
                        confirmyn: "Y",
                        stdcardno: "",
                        class: "",
                        appinstallyn: "N",
                        pointplus: 0,
                        pointminus: 0,
                        seatno: registerSeatno,
                    })
                    
                    Swal.fire({
                        icon: "success",
                        title: "등록 완료",
                        // text: "",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                            navigate("/studentmng");
                        }
                    });
                }catch(error){
                    console.log(error)
                }
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집 실패",
                text: "빈 값이 없어야 합니다.",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        }
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div style={{display:'flex',justifyContent:'flex-end',width:"100%",marginTop:"3.125rem"}}>
                    <img src={BtnClose} style={{width:"1.5rem",height:"1.5rem",marginRight:"3.125rem",cursor:"pointer"}} alt='닫기'
                        onClick={()=>navigate("/studentmng")}
                    ></img>
                </div>
                <div style={{width:"27.5rem",fontWeight:500,fontSize:"1.625rem",marginBottom:"2.5rem"}}>수강생 편집</div>
                <div className="unit_title">이름</div>
                {colId !== 'new' && appInstallYn === 'Y' ? (
                    <div className="read_only">{studentData.username}</div>
                ) : (
                    <input id="_username" 
                        type="text" 
                        placeholder="수강생 이름" 
                        value={registerUsername}
                        onChange={(e)=>{setRegisterUsername(e.target.value);}}
                    ></input>
                )}
                <div className="unit_title">전화번호</div>
                {colId !== 'new' && appInstallYn === 'Y' ? (
                    <div className="read_only">{studentData.userphone}</div>
                ) : (
                    <input id="_userphone" 
                        type="text" 
                        placeholder="수강생 전화번호" 
                        value={registerUserphone}
                            onChange={(e)=>{
                            const { value } = e.target
                            const onlyNumber = value.replace(/[^0-9]/g, '')
                            setRegisterUserphone(onlyNumber);
                        }}
                    ></input>
                )}
                <div className="unit_title">학부모 전화번호</div>
                <input id="_parentphone" 
                    type="text" 
                    placeholder="학부모 전화번호" 
                    value={registerParentphone}
                    onChange={(e)=>{
                        const { value } = e.target
                        const onlyNumber = value.replace(/[^0-9]/g, '')
                        setRegisterParentphone(onlyNumber);
                    }}
                ></input>
                {/* <div className="unit_title">학교</div>
                <input id="_school" 
                    type="text" 
                    placeholder="학교명" 
                    value={registerSchool}
                    onChange={(e)=>{setRegisterSchool(e.target.value);}}
                ></input> */}
                <div style={{width:"27.5rem",display:'flex',justifyContent:'space-between'}}>
                    <div style={{width:"12.5rem"}}>
                        <div className="unit_title">학교</div>
                        <input id="_school" 
                            type="text" 
                            placeholder="학교명" 
                            value={registerSchool}
                            onChange={(e)=>{setRegisterSchool(e.target.value);}}
                            style={{width:"12.5rem"}}
                        ></input>
                    </div>
                    <div style={{width:"12.5rem"}}>
                        <div className="unit_title">좌석번호</div>
                        <input id="_seatno" 
                            type="text" 
                            placeholder="숫자만 입력(예:3)" 
                            value={registerSeatno}
                            onChange={(e)=>{
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')
                                setRegisterSeatno(onlyNumber);}}
                            style={{width:"12.5rem"}}
                        ></input>
                    </div>
                </div>
                <div style={{width:"27.5rem",display:'flex',justifyContent:'space-between',marginBottom:"3.125rem"}}>
                    <div style={{width:"12.5rem"}}>
                        <div className="unit_title" style={{width:"12.5rem"}}>학년</div>
                        <div className="select_warp">
                            <select value={registerGrade} onChange={(e)=>setRegisterGrade(e.target.value)}>
                                {registerGrade === "" && <option></option>}
                                <option value="1">1학년</option>
                                <option value="2">2학년</option>
                                <option value="3">3학년</option>
                            </select>
                            <img src={BtnDown} className="button_img" alt='콤보'></img>
                        </div>
                    </div>
                    <div style={{width:"12.5rem"}}>
                        <div className="unit_title" style={{width:"12.5rem"}}>성별</div>
                        <div className="select_warp">
                            <select value={registerGender} onChange={(e)=>setRegisterGender(e.target.value)}>
                                {registerGender === "" && <option></option>}
                                <option value="M">남자</option>
                                <option value="F">여자</option>
                            </select>
                            <img src={BtnDown} className="button_img" alt='콤보'></img>
                        </div>
                    </div>
                </div>
                <div style={{width:"27.5rem",height:"4rem",borderRadius:"3.125rem",backgroundColor:"#11167B",
                            textAlign:'center',color:"#FFFFFF",fontWeight:500,fontSize:"1.125rem",lineHeight:"4rem",
                            cursor:'pointer'}}
                    onClick={saveUserData}
                >완료</div>
            </div>
            {/* 태블릿 영역 */}
            <div className="modal-content_tablet">
                <div style={{display:'flex',justifyContent:'flex-end',width:"100%",marginTop:"3.125rem"}}>
                    <img src={BtnClose} style={{width:"1.5rem",height:"1.5rem",marginRight:"3.125rem",cursor:"pointer"}} alt='닫기'
                        onClick={()=>navigate("/studentmng")}
                    ></img>
                </div>
                <div style={{width:"75%",fontWeight:500,fontSize:"1.625rem",marginBottom:"2.5rem"}}>수강생 편집</div>
                <div className="unit_title">이름</div>
                <div style={{width:"75%",height:"3.125rem",marginBottom:"0.625rem"}}>
                    {colId !== 'new' && appInstallYn === 'Y' ? (
                        <div className="read_only">{studentData.username}</div>
                    ) : (
                        <input id="_username" 
                            type="text" 
                            placeholder="수강생 이름" 
                            value={registerUsername}
                            onChange={(e)=>{setRegisterUsername(e.target.value);}}
                            style={{width:"100%"}}
                        ></input>
                    )}
                </div>
                <div className="unit_title">전화번호</div>
                <div style={{width:"75%",height:"3.125rem",marginBottom:"0.625rem"}}>
                    {colId !== 'new' && appInstallYn === 'Y' ? (
                        <div className="read_only">{studentData.userphone}</div>
                    ) : (
                        <input id="_userphone" 
                            type="text" 
                            placeholder="수강생 전화번호" 
                            value={registerUserphone}
                                onChange={(e)=>{
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')
                                setRegisterUserphone(onlyNumber);
                            }}
                            style={{width:"100%"}}
                        ></input>
                    )}
                </div>
                <div className="unit_title">학부모 전화번호</div>
                <div style={{width:"75%",height:"3.125rem",marginBottom:"0.625rem"}}>
                    <input id="_parentphone" 
                        type="text" 
                        placeholder="학부모 전화번호" 
                        value={registerParentphone}
                        onChange={(e)=>{
                            const { value } = e.target
                            const onlyNumber = value.replace(/[^0-9]/g, '')
                            setRegisterParentphone(onlyNumber);
                        }}
                        style={{width:"100%"}}
                    ></input>
                </div>
                {/* <div className="unit_title">학교</div>
                <div style={{width:"75%",height:"50px",marginBottom:"10px"}}>
                    <input id="_school" 
                        type="text" 
                        placeholder="학교명" 
                        value={registerSchool}
                        onChange={(e)=>{setRegisterSchool(e.target.value);}}
                        style={{width:"100%"}}
                    ></input>
                </div> */}
                <div style={{width:"75%",display:'flex',justifyContent:'space-between'}}>
                    <div style={{width:"45%"}}>
                        <div className="unit_title">학교</div>
                        <input id="_school" 
                            type="text" 
                            placeholder="학교명" 
                            value={registerSchool}
                            onChange={(e)=>{setRegisterSchool(e.target.value);}}
                            style={{width:"100%"}}
                        ></input>
                    </div>
                    <div style={{width:"45%"}}>
                        <div className="unit_title">좌석번호</div>
                        <input id="_seatno" 
                            type="text" 
                            placeholder="숫자만 입력(예:3)" 
                            value={registerSeatno}
                            onChange={(e)=>{
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')
                                setRegisterSeatno(onlyNumber);}}
                            style={{width:"100%"}}
                        ></input>
                    </div>
                </div>
                <div style={{width:"75%",display:'flex',justifyContent:'space-between',marginBottom:"3.125rem"}}>
                    <div style={{width:"45%"}}>
                        <div className="unit_title" style={{width:"100%"}}>학년</div>
                        <div className="select_warp">
                            <select value={registerGrade} onChange={(e)=>setRegisterGrade(e.target.value)}>
                                {registerGrade === "" && <option></option>}
                                <option value="1">1학년</option>
                                <option value="2">2학년</option>
                                <option value="3">3학년</option>
                            </select>
                            <img src={BtnDown} className="button_img" alt='콤보'></img>
                        </div>
                    </div>
                    <div style={{width:"45%"}}>
                        <div className="unit_title" style={{width:"100%"}}>성별</div>
                        <div className="select_warp">
                            <select value={registerGender} onChange={(e)=>setRegisterGender(e.target.value)}>
                                {registerGender === "" && <option></option>}
                                <option value="M">남자</option>
                                <option value="F">여자</option>
                            </select>
                            <img src={BtnDown} className="button_img" alt='콤보'></img>
                        </div>
                    </div>
                </div>
                <div style={{width:"75%",height:"4rem",borderRadius:"3.125rem",backgroundColor:"#11167B",
                            textAlign:'center',color:"#FFFFFF",fontWeight:500,fontSize:"1.125rem",lineHeight:"4rem",
                            cursor:'pointer',marginBottom:"1.875rem"}}
                    onClick={saveUserData}
                >완료</div>
            </div>
            {/* 모바일 영역 */}
            <div className="modal-content_mobile">
                <div style={{display:'flex',justifyContent:'flex-end',width:"100%",marginTop:"1.5rem",paddingRight:"1.5rem"}}>
                    <img src={BtnClose} style={{width:"1.5rem",height:"1.5rem",cursor:"pointer"}} alt='닫기'
                        onClick={()=>navigate("/studentmng")}
                    ></img>
                </div>
                <div style={{padding:"0rem 3rem 1.5rem 3rem"}}>
                    <div className="title">수강생 편집</div>
                    <div style={{marginBottom:"1.5rem"}}>
                        <div className="subtitle">이름</div>
                        {colId !== 'new' && appInstallYn === 'Y' ? (
                            <div className="read_only">{studentData.username}</div>
                        ) : (
                            <input id="_username_mobile" 
                                type="text" 
                                placeholder="수강생 이름" 
                                value={registerUsername}
                                onChange={(e)=>{setRegisterUsername(e.target.value);}}
                            ></input>
                        )}
                    </div>
                    <div style={{marginBottom:"1.5rem"}}>
                        <div className="subtitle">전화번호</div>
                        {colId !== 'new' && appInstallYn === 'Y' ? (
                            <div className="read_only">{studentData.userphone}</div>
                        ) : (
                            <input id="_userphone_mobile" 
                                type="text" 
                                placeholder="수강생 전화번호" 
                                value={registerUserphone}
                                onChange={(e)=>{
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    setRegisterUserphone(onlyNumber);
                                }}
                            ></input>
                        )}
                    </div>
                    <div style={{marginBottom:"1.5rem"}}>
                        <div className="subtitle">학부모 전화번호</div>
                        <input id="_parentphone_mobile" 
                            type="text" 
                            placeholder="학부모 전화번호" 
                            value={registerParentphone}
                            onChange={(e)=>{
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')
                                setRegisterParentphone(onlyNumber);
                            }}
                        ></input>
                    </div>
                    <div style={{marginBottom:"1.5rem"}}>
                        <div className="subtitle">학교</div>
                        <input id="_school_mobile" 
                            type="text" 
                            placeholder="학교명" 
                            value={registerSchool}
                            onChange={(e)=>{setRegisterSchool(e.target.value);}}
                        ></input>
                    </div>
                    <div style={{marginBottom:"1.5rem"}}>
                        <div className="subtitle">좌석번호</div>
                        <input id="_seatno_mobile" 
                            type="text" 
                            placeholder="숫자만 입력(예:3)" 
                            value={registerSeatno}
                            onChange={(e)=>{
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')
                                setRegisterSeatno(onlyNumber);}}
                        ></input>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between',marginBottom:'2rem'}}>
                        <div>
                            <div className="subtitle half">학년</div>
                            <div className="select_warp">
                                <select value={registerGrade} 
                                    style={{width:"7.5rem"}}
                                    onChange={(e)=>setRegisterGrade(e.target.value)}
                                >
                                    {registerGrade === "" && <option></option>}
                                    <option value="1">1학년</option>
                                    <option value="2">2학년</option>
                                    <option value="3">3학년</option>
                                </select>
                                <img src={BtnDown} alt='콤보' />
                            </div>
                        </div>
                        <div>
                            <div className="subtitle half">성별</div>
                            <div className="select_warp">
                                <select value={registerGender} 
                                    style={{width:"7.5rem"}}
                                    onChange={(e)=>setRegisterGender(e.target.value)}
                                >
                                    {registerGender === "" && <option></option>}
                                    <option value="M">남자</option>
                                    <option value="F">여자</option>
                                </select>
                                <img src={BtnDown} alt='콤보' />
                            </div>
                        </div>
                    </div>
                    <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <button className="complete" onClick={saveUserData}>완료</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewModStudent;