import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, getDocs, where, deleteDoc, doc, orderBy, limit, startAfter, updateDoc, setDoc } from "firebase/firestore";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Swal from "sweetalert2";
import BtnDown from '../images/buttons/arrow_down.png';
import BtnArrowDown from '../images/buttons/arrow_down_grey_16.png'
import AttendIcon from '../images/buttons/attend_icon_mobile.svg';
import ArrowUpDown from '../images/buttons/arrow_updown_mobile.svg';
import Etc from '../images/buttons/etc.svg';
import EtcMod from '../images/buttons/etc_mod_mobile.svg';
import EtcDel from '../images/buttons/etc_del_mobile.svg';


function AttendMng() {
    const navigate = useNavigate();
    const calendarRef = useRef(null);
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth()+1).toString();
    const day = now.getDate().toString();
    //const now_date = year+"년 "+month+"월 "+day+"일";
    
    let monthVal;
    if(month.length === 1) {
        monthVal = "0"+month;
    }else{
        monthVal = month;
    }

    let dayVal;
    if(day.length === 1) {
        dayVal = "0"+day;
    }else{
        dayVal = day;
    }

    const [attendData, setAttendData] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    
    const [displayDate, setDisplayDate] = useState(year+"년 "+month+"월 "+day+"일");
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
    const [showCalendar, setShowCalendar] = useState(false);  //달력 보이기 기능
    const [selectedDate, setSelectedDate] = useState(`${year}${monthVal}${dayVal}`);

    //강제 리렌더링 처리 state
    const [updateState, setUpdateState] = useState(false);
    const [sortCriteria, setSortCriteria] = useState('seatno'); // 초기 정렬 기준은 좌석번호
    const [checkinInfo, setCheckinInfo] = useState("");
    const [buttonColor1, setButtonColor1] = useState("#FFFFFF");
    const [buttonColor2, setButtonColor2] = useState("#FFFFFF");

    const [showMenu, setShowMenu] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, right: 0 });
    const menuRef = useRef(null);
    
    //setSelectedDate(year+"년 "+month+"월 "+day+"일");

    const callDb = useCallback(async () => {
        //설문조사 데이터 조회 변수
        const q = query( collection(db, "attendance"), 
                         where("attenddate", "==", selectedDate), 
                         orderBy("entertime"),
                         limit(20)
                        );
        const snapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;
        // Set the last visible document to be used in the next query
        if (snapshot.docs.length > 0) {
            setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        }

        const attData = snapshot.docs.map(doc => {
            const data = doc.data();
            let entertimemobile = '';
            let exittimemobile = '';
            
            const formatTimestamp = (timestamp) => {
                if (timestamp && timestamp.toDate) {
                    const date = timestamp.toDate();
                    return date.toLocaleString('ko-KR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }).replace(/\. /g, '/').replace(', ', ' ').replace(/\/$/, '').replace(/\/(?=\d{2}:)/, ' ');
                }
                return '';
            };
    
            entertimemobile = formatTimestamp(data.entertime);
            exittimemobile = formatTimestamp(data.exittime);
    
            return {
                ...data,
                id: doc.id,
                entertimemobile,
                exittimemobile
            };
        });

        setAttendData(attData);
    },[selectedDate])
  
    useEffect(() => {
        callDb();
    },[callDb, updateState])

    const loadMoreData = async () => {
        try {
            const q = query( collection(db, "attendance"), 
                         where("attenddate", "==", selectedDate), 
                         orderBy("entertime"),
                         startAfter(lastVisible),
                         limit(20)
                        );
            
            const snapshot = await getDocs(q);
            const attData = snapshot.docs.map(doc => {
                const data = doc.data();
                let entertimemobile = '';
                let exittimemobile = '';
                
                const formatTimestamp = (timestamp) => {
                    if (timestamp && timestamp.toDate) {
                        const date = timestamp.toDate();
                        return date.toLocaleString('ko-KR', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        }).replace(/\. /g, '/').replace(', ', ' ').replace(/\/$/, '').replace(/\/(?=\d{2}:)/, ' ');
                    }
                    return '';
                };
        
                entertimemobile = formatTimestamp(data.entertime);
                exittimemobile = formatTimestamp(data.exittime);
        
                return {
                    ...data,
                    id: doc.id,
                    entertimemobile,
                    exittimemobile
                };
            });
        
            if (snapshot.docs.length > 0) {
                setAttendData([...attendData, ...attData]);
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            } else {
                Swal.fire({
                    icon: "warning",
                    title: "검색 불가",
                    text: "마지막 데이터 입니다.",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });
            }
        } catch (error) {
            console.error('Error fetching more data:', error);
        }
    };

    const handleDateClick = (date) => {
        const selYear = date.getFullYear();
        const selMonth = (date.getMonth()+1).toString();
        const selDay = date.getDate().toString();
        setDisplayDate(selYear+"년 "+selMonth+"월 "+selDay+"일");
        setShowCalendar(!showCalendar);
        setButtonColor1("#FFFFFF");
        setButtonColor2("#FFFFFF");

        let monthVal;
        if(selMonth.length === 1) {
            monthVal = "0"+selMonth;
        }else{
            monthVal = selMonth;
        }

        let dayVal;
        if(selDay.length === 1) {
            dayVal = "0"+selDay;
        }else{
            dayVal = selDay;
        }

        setSelectedDate(`${selYear}${monthVal}${dayVal}`);
    };

    const attendDataDel = async (props)=>{
        await deleteDoc(doc(db, "attendance", props))

        Swal.fire({
            icon: "success",
            title: "삭제 완료",
            text: "출석데이터 삭제가 완료되었습니다",
            //showCancelButton: true,
            confirmButtonText: "확인",
            //cancelButtonText: "취소",
        }).then((res) => {
            /* Read more about isConfirmed, isDenied below */
            if (res.isConfirmed) {
                //확인 요청 처리
            }
        });
        setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
    };

    function ViewCalendar() {
        const currentDate = new Date(); // 현재 날짜로 설정
      
        return (
            <>
                {showCalendar && (
                    <div className="sliding_calendar" 
                        style={{ top: calendarPosition.top, left: calendarPosition.left }}
                        ref={calendarRef}
                    >
                        <Calendar
                            onChange={handleDateClick}
                            defaultActiveStartDate={currentDate}
                        />
                    </div>
                )}
            </>
        );
    }

    const handleEtcClick = (event, docId) => {
        event.preventDefault();
        const rect = event.target.getBoundingClientRect();
        setMenuPosition({
            top: rect.bottom + window.scrollY,
            right: window.innerWidth - rect.right + window.scrollX
        });
        setShowMenu(docId);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);


    const toAbsenceMng = () => {
        navigate("/absencemng")
    }

    const handleSortClick = (criteria) => {
        setSortCriteria(criteria);
        
        if(criteria === "seatno"){
            setButtonColor1("#BFD5FF");
            setButtonColor2("#FFFFFF");
        }else if(criteria === "stdname"){
            setButtonColor1("#FFFFFF");
            setButtonColor2("#BFD5FF");
        }
    };

    const attendInfoMode = (popupName, id, val) => {
        navigate("/popup", {
            state: {
                popupName: popupName,
                colId: id,
                val: val,
            },
        })
    };

    function isNumericString(props) {
        // 정규식을 사용하여 문자열이 숫자로만 이루어져 있는지 확인
        const numericRegex = /^[0-9]+$/;
        return numericRegex.test(props);
    }

    const checkProcess = async (checkdiv, colid) => {
        const now = new Date(); // 현재 날짜 및 시간 가져오기
        const year = now.getFullYear(); // 연도
        const month = String(now.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1 필요)
        const day = String(now.getDate()).padStart(2, '0'); // 일
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        if(checkdiv === "checkout"){
            try{
                const docRef = doc(db, "attendance", colid);
                await updateDoc(docRef, {
                    completeyn: 'Y',
                    exittime: now,
                    exittimeprint: year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분"
                });
                setUpdateState(!updateState);
              } catch(error){
                console.log(error.message);
            }
        }else if(checkdiv === "checkin"){
            try{     
                let qq;
                if(isNumericString(colid) === true){
                    qq = query(collection(db, "attendance"), where("attenddate", "==", `${year}${month}${day}`), 
                            where("seatno", "==", colid), where("groupid", "==", "sd3"));
                }else{
                    qq = query(collection(db, "attendance"), where("attenddate", "==", `${year}${month}${day}`), 
                            where("stdname", "==", colid), where("groupid", "==", "sd3"));
                }
                const qquerySnapshot = await getDocs(qq); //students 테이블의 stdcardno 중에 태그한 카드값이 있는지;
                if (qquerySnapshot.docs.length === 0) {
                    let q;
                    if(isNumericString(colid) === true){
                        q = query(collection(db, "users"), where("seatno", "==", colid), where("groupid", "==", "sd3"));
                    }else{
                        q = query(collection(db, "users"), where("username", "==", colid), where("groupid", "==", "sd3"));
                    }
                    const querySnapshot = await getDocs(q); //students 테이블의 stdcardno 중에 태그한 카드값이 있는지;
                    const userData = querySnapshot.docs.map(doc => ({
                        ...doc.data(),
                        id: doc.id
                    }));

                    if (querySnapshot.docs.length !== 0) {
                        await setDoc(doc(db, "attendance", userData[0].userphone+year+month+day+hours+minutes),{
                            stdname: userData[0].username,
                            stdphone: userData[0].userphone,
                            stdcardno: "",
                            stdid: userData[0].userid,
                            // entertimeprint: year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분 "+seconds+"초",
                            entertimeprint: year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분",
                            entertime: now,
                            exittimeprint: '',
                            exittime: '',
                            attenddate: `${year}${month}${day}`,
                            completeyn: 'N',
                            unauthabsence: 'N',
                            unauthregtime: '',
                            unauthdate: '',
                            unauthcomment: '',
                            seatno: userData[0].seatno,
                            groupid: 'sd3',
                        });
                        setUpdateState(!updateState);
                        setCheckinInfo("");
                    }else{
                        Swal.fire({
                        icon: "error",
                        title: "출석 실패",
                        text: "등록된 수강생 정보가 없습니다.",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                        }).then((res) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (res.isConfirmed) {
                                //확인 요청 처리
                            }
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "출석 실패",
                        text: "이미 등록된 출석정보가 있습니다.",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                        }).then((res) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (res.isConfirmed) {
                                //확인 요청 처리
                            }
                        });
                }
              } catch(error) {
                console.log("에러메세지:", error.message);
            }
        }
    }

    return (
        <>
            <div className="content_wrap">
                <div style={{display:'flex',width:'100%',marginTop:"1.25rem",justifyContent:'space-between'}}>
                    <div style={{display:'flex'}}>
                        <div className="title_absent">출석관리</div>
                        <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                        <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                            onClick={toAbsenceMng}
                        >결석관리</div>
                    </div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"15rem",
                                borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름 / 좌석번호"
                            style={{width:"9.375rem",fontWeight:400,fontSize:"0.9375rem",color:"#A6A7AB"}}     
                            value={checkinInfo}
                            onChange={(e)=>{setCheckinInfo(e.target.value)}}
                        />
                        <button style={{width:"5.375rem",marginBottom:"0.125rem",backgroundColor:"#11167B",
                                    color:"#FFFFFF",fontSize:"0.9375rem",fontWeight:"500",height:"2.25rem",
                                    borderRadius:"0.5rem"}}
                            onClick={()=>{
                                Swal.fire({
                                    icon: "question",
                                    title: "입실 처리",
                                    text: "입실처리 하시겠습니까?",
                                    showCancelButton: true,
                                    confirmButtonText: "출석",
                                    cancelButtonText: "취소",
                                }).then((res) => {
                                    if (res.isConfirmed) {
                                        checkProcess("checkin", checkinInfo);
                                    }
                                });
                            }}
                        >입실처리</button>
                    </div>
                </div>
                <div style={{width:"100%",marginTop:"4.375rem",marginBottom:"2.5rem",display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:"16.13rem",height:"4rem",borderRadius:"0.5rem",backgroundColor:"#F6F6F6"}}
                        onClick={(e)=>{ 
                            const { top, left, height } = e.target.getBoundingClientRect();
                            const newTop = top + height; // 원하는 위치 조절
                            const newLeft = left; // 원하는 위치 조절
                            setCalendarPosition({ top: newTop, left: newLeft });
                            setShowCalendar(!showCalendar);
                    }}>
                        <div style={{color:"#0F5EFE",fontWeight:500,fontSize:"1.5rem",marginRight:"0.625rem"}}>{displayDate}</div>
                        {/* <div style={{width:"128px",height:"40px",border:"#E7E8E9 1px solid",
                                    borderRadius:"50px",textAlign:'center',lineHeight:'40px',color:"#8D8D8D",
                                    fontWeight:500,fontSize:"16px"}}
                            onClick={(e)=>{ 
                                const { top, left, height } = e.target.getBoundingClientRect();
                                const newTop = top + height; // 원하는 위치 조절
                                const newLeft = left; // 원하는 위치 조절
                                setCalendarPosition({ top: newTop, left: newLeft 22s});
                                setShowCalendar(!showCalendar);
                            }}
                        >기준일선택</div> */}
                        <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                    </div>
                    <div style={{display:'flex'}}>
                        <div className="btn_midside" style={{width:"4.5rem",paddingLeft:"0.625rem",backgroundColor:buttonColor1}} onClick={() => handleSortClick('seatno')}>
                            좌석순
                            <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem",marginLeft:"0.125rem"}}/>
                        </div>
                        <div className="btn_midside" style={{width:"4.5rem",paddingLeft:"0.625rem",marginRight:"0px",backgroundColor:buttonColor2}} onClick={() => handleSortClick('stdname')}>
                            이름순
                            <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem",marginLeft:"0.125rem"}}/>
                        </div>
                    </div>
                </div>
                <ViewCalendar />
                <div style={{width:"100%",height:"2.5rem",display:'flex',color:"#8D8D8D",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#EEEEEE 1px solid",lineHeight:"2.5rem"}}>
                    <div style={{width:"12.13rem"}}>이름</div>
                    <div style={{width:"9.375rem"}}>좌석번호</div>
                    <div style={{width:"15.63rem"}}>전화번호</div>
                    <div style={{width:"18.75rem"}}>입실정보</div>
                    <div style={{width:"18.75rem"}}>퇴실정보</div>
                    <div style={{width:"9.375rem"}} />
                </div>
                {attendData
                    .sort((a, b) => {
                        if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'stdname') {
                            // 이름을 문자열로 비교
                            return a.stdname.localeCompare(b.stdname);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        const formatPhoneNumber = (phoneNumber) => {
                            const cleaned = ('' + phoneNumber).replace(/\D/g, '');
                            const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                        
                            if (match) {
                            return match[1] + '-' + match[2] + '-' + match[3];
                            }
                        
                            return phoneNumber;
                        };

                        return(
                            <div style={{width:"100%",height:"2.5rem",display:'flex',
                                            color:"#191919",fontWeight:500,fontSize:"0.875rem",
                                            lineHeight:"0.875rem",alignItems:'center',borderBottom:"#EEEEEE 1px solid",
                                        }} key={doc.id}>
                                <div style={{width:"12.13rem"}}>{doc.stdname}</div>
                                <div style={{width:"9.375rem"}}>{doc.seatno}</div>
                                <div style={{width:"15.63rem"}}>{formatPhoneNumber(doc.stdphone)}</div>
                                <div style={{width:"18.75rem"}}>{doc.entertimeprint}</div>
                                {doc.exittimeprint !== "" ? (
                                    <div style={{width:"18.75rem"}}>{doc.exittimeprint}</div>
                                ) : (
                                    <div style={{width:"18.75rem"}}>
                                        <button className="btn_moddel" 
                                            style={{width:"12.5rem",marginLeft:"0px",color:"#FF594F"}}
                                            onClick={()=>{
                                                Swal.fire({
                                                    icon: "question",
                                                    title: "퇴실 처리",
                                                    text: "퇴실처리 하시겠습니까?",
                                                    showCancelButton: true,
                                                    confirmButtonText: "퇴실",
                                                    cancelButtonText: "취소",
                                                }).then((res) => {
                                                    if (res.isConfirmed) {
                                                        checkProcess("checkout",doc.id);
                                                    }
                                                });
                                            }}
                                        >퇴실처리</button>
                                    </div>
                                )}
                                
                                <div style={{width:"9.375rem",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <button className="btn_moddel" style={{color:"#8D8D8D"}} onClick={()=>attendInfoMode("attendinfomod", doc.id)}>수정</button>
                                    <button className="btn_moddel" style={{color:"#FF594F"}} 
                                        onClick={()=>{
                                            Swal.fire({
                                                icon: "question",
                                                title: "출석데이터 삭제",
                                                text: "출석데이터 삭제를 하시겠습니까?",
                                                showCancelButton: true,
                                                confirmButtonText: "삭제",
                                                cancelButtonText: "취소",
                                            }).then((res) => {
                                                if (res.isConfirmed) {
                                                    attendDataDel(doc.id);
                                                }
                                            });
                                        }}
                                    >삭제</button>
                                </div>
                            </div>
                        )
                    })
                } 
                <button style={{width:"6.375rem", height:"2.625rem", backgroundColor:"#11167B",
                            color:"#FFFFFF", fontWeight:500, fontSize:"0.9375rem", borderRadius:"3.125rem",
                            marginTop:"3.125rem"}} 
                        onClick={loadMoreData}
                >더보기</button>
                <footer>
                    <div className="copyright" style={{marginTop:"3.125rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{display:'flex',width:"95%",marginTop:"1.25rem",justifyContent:'space-between'}}>
                    <div style={{display:'flex'}}>
                        <div className="title_absent">출석관리</div>
                        <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                        <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                            onClick={toAbsenceMng}
                        >결석관리</div>
                    </div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"15rem",
                                borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름 / 좌석번호"
                            style={{width:"9.375rem",fontWeight:400,fontSize:"0.9375rem",color:"#A6A7AB"}}     
                            value={checkinInfo}
                            onChange={(e)=>{setCheckinInfo(e.target.value)}}
                        />
                        <button style={{width:"5.375rem",marginBottom:"0.125rem",backgroundColor:"#11167B",
                                    color:"#FFFFFF",fontSize:"0.9375rem",fontWeight:"500",height:"2.25rem",
                                    borderRadius:"0.5rem"}}
                            onClick={()=>{
                                Swal.fire({
                                    icon: "question",
                                    title: "입실 처리",
                                    text: "입실처리 하시겠습니까?",
                                    showCancelButton: true,
                                    confirmButtonText: "출석",
                                    cancelButtonText: "취소",
                                }).then((res) => {
                                    if (res.isConfirmed) {
                                        checkProcess("checkin", checkinInfo);
                                    }
                                });
                            }}
                        >입실처리</button>
                    </div>
                </div>
                <div style={{width:"95%",marginTop:"4.375rem",marginBottom:"2.5rem",display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:"16.13rem",height:"4rem",borderRadius:"0.5rem",backgroundColor:"#F6F6F6"}}
                        onClick={(e)=>{ 
                            const { top, left, height } = e.target.getBoundingClientRect();
                            const newTop = top + height; // 원하는 위치 조절
                            const newLeft = left; // 원하는 위치 조절
                            setCalendarPosition({ top: newTop, left: newLeft });
                            setShowCalendar(!showCalendar);
                    }}>
                        <div style={{color:"#0F5EFE",fontWeight:500,fontSize:"1.5rem",marginRight:"0.625rem"}}>{displayDate}</div>
                        {/* <div style={{width:"128px",height:"40px",border:"#E7E8E9 1px solid",
                                    borderRadius:"50px",textAlign:'center',lineHeight:'40px',color:"#8D8D8D",
                                    fontWeight:500,fontSize:"16px"}}
                            onClick={(e)=>{ 
                                const { top, left, height } = e.target.getBoundingClientRect();
                                const newTop = top + height; // 원하는 위치 조절
                                const newLeft = left; // 원하는 위치 조절
                                setCalendarPosition({ top: newTop, left: newLeft });
                                setShowCalendar(!showCalendar);
                            }}
                        >기준일선택</div> */}
                        <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                    </div>
                    <div style={{display:'flex'}}>
                        <div className="btn_midside" style={{width:"4.5rem",paddingLeft:"0.625rem",backgroundColor:buttonColor1}} onClick={() => handleSortClick('seatno')}>
                            좌석순
                            <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem",marginLeft:"0.125rem"}}/>
                        </div>
                        <div className="btn_midside" style={{width:"4.5rem",paddingLeft:"0.625rem",marginRight:"0px",backgroundColor:buttonColor2}} onClick={() => handleSortClick('stdname')}>
                            이름순
                            <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem",marginLeft:"0.125rem"}}/>
                        </div>
                    </div>
                </div>
                <ViewCalendar />
                <div style={{width:"95%",height:"3rem",display:'flex',color:"#A6A7AA",fontWeight:400,fontSize:"1rem",borderBottom:"#EEEEEE 1px solid",lineHeight:"3rem"}}>
                    <div style={{width:"13%"}}>이름</div>
                    <div style={{width:"10%"}}>좌석번호</div>
                    <div style={{width:"16%"}}>전화번호</div>
                    <div style={{width:"22%"}}>입실정보</div>
                    <div style={{width:"22%"}}>퇴실정보</div>
                    <div style={{width:"10%"}} />
                </div>
                {attendData
                    .sort((a, b) => {
                        if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'stdname') {
                            // 이름을 문자열로 비교
                            return a.stdname.localeCompare(b.stdname);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        const formatPhoneNumber = (phoneNumber) => {
                            const cleaned = ('' + phoneNumber).replace(/\D/g, '');
                            const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                        
                            if (match) {
                            return match[1] + '-' + match[2] + '-' + match[3];
                            }
                        
                            return phoneNumber;
                        };

                        return(
                            <div style={{width:"95%",height:"3rem",display:'flex',
                                            color:"#191919",fontWeight:500,fontSize:"0.875rem",
                                            lineHeight:"3rem",alignItems:'center',borderBottom:"#EEEEEE 1px solid",
                                        }} key={doc.id}>
                                <div style={{width:"13%"}}>{doc.stdname}</div>
                                <div style={{width:"10%"}}>{doc.seatno}</div>
                                <div style={{width:"16%"}}>{formatPhoneNumber(doc.stdphone)}</div>
                                <div style={{width:"22%"}}>{doc.entertimeprint}</div>
                                {doc.exittimeprint !== "" ? (
                                    <div style={{width:"22%"}}>{doc.exittimeprint}</div>
                                ) : (
                                    <div style={{width:"22%"}}>
                                        <button className="btn_moddel" 
                                            style={{width:"70%",marginLeft:"0px",color:"#FF594F"}}
                                            onClick={()=>{
                                                Swal.fire({
                                                    icon: "question",
                                                    title: "퇴실 처리",
                                                    text: "퇴실처리 하시겠습니까?",
                                                    showCancelButton: true,
                                                    confirmButtonText: "퇴실",
                                                    cancelButtonText: "취소",
                                                }).then((res) => {
                                                    if (res.isConfirmed) {
                                                        checkProcess("checkout",doc.id);
                                                    }
                                                });
                                            }}
                                        >퇴실처리</button>
                                    </div>
                                )}
                                <div style={{width:"10%",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <button className="btn_moddel" style={{color:"#8D8D8D"}} onClick={()=>attendInfoMode("attendinfomod", doc.id)}>수정</button>
                                    <button className="btn_moddel" style={{color:"#FF594F"}} 
                                        onClick={()=>{
                                            Swal.fire({
                                                icon: "question",
                                                title: "출석데이터 삭제",
                                                text: "출석데이터 삭제를 하시겠습니까?",
                                                showCancelButton: true,
                                                confirmButtonText: "삭제",
                                                cancelButtonText: "취소",
                                            }).then((res) => {
                                                if (res.isConfirmed) {
                                                    attendDataDel(doc.id);
                                                }
                                            });
                                        }}
                                    >삭제</button>
                                </div>
                            </div>
                        )
                    })
                } 
                <button style={{width:"6.375rem", height:"2.625rem", backgroundColor:"#11167B",
                            color:"#FFFFFF", fontWeight:500, fontSize:"0.9375rem", borderRadius:"3.125rem",
                            marginTop:"3.125rem"}} 
                        onClick={loadMoreData}
                >더보기</button>
                <footer>
                    <div className="copyright" style={{marginTop:"3.125rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile padding_horizonal">
                <div className="sub_title attendmng">
                    <img src={AttendIcon} alt='출결관리' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                    <div>출석관리</div>
                    <div className="notselected" style={{margin:'0rem 0.3rem'}}>·</div>
                    <div className="notselected" onClick={toAbsenceMng}>결석관리</div>
                </div>
                <div className="calendar_area"
                    onClick={(e)=>{ 
                        setCalendarPosition({ top: '17rem', left: '1.25rem' });
                        setShowCalendar(!showCalendar);
                }}>
                    <div className="calendar_text">{displayDate}</div>
                    <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                </div>
                <ViewCalendar />
                <div style={{display:'flex',justifyContent:'space-between',marginTop:'3.12rem'}}>
                    <div className="btn_midside" 
                        onClick={() => {
                            if(sortCriteria === "seatno"){
                                handleSortClick('stdname')
                            }else{
                                handleSortClick('seatno')
                            }
                        }}
                    >
                        {sortCriteria === "seatno" ? "좌석순"
                        : "이름순"
                        }
                        <img src={ArrowUpDown} alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                    </div>
                    <div className="make_attend_area">
                        <input type="text" placeholder="이름 / 좌석"     
                            value={checkinInfo}
                            onChange={(e)=>{setCheckinInfo(e.target.value)}}
                        />
                        <button 
                            onClick={()=>{
                                Swal.fire({
                                    icon: "question",
                                    title: "입실 처리",
                                    text: "입실처리 하시겠습니까?",
                                    showCancelButton: true,
                                    confirmButtonText: "출석",
                                    cancelButtonText: "취소",
                                }).then((res) => {
                                    if (res.isConfirmed) {
                                        checkProcess("checkin", checkinInfo);
                                    }
                                });
                            }}
                        >입실</button>
                    </div>
                </div>
                <div className="header_area_mobile_container">
                    <div className="header_area_mobile">
                        <div className="name">이름</div>
                        <div className="seat">좌석번호</div>
                        <div className="enter">입실정보</div>
                        <div className="exit">퇴실정보</div>
                    </div>
                    {attendData
                        .sort((a, b) => {
                            if (sortCriteria === 'seatno') {
                                // 좌석번호 숫자를 비교
                                if (a.seatno !== "" && b.seatno !== "") {
                                    return a.seatno - b.seatno;
                                } else if (a.seatno === "") {
                                    return 1; // null은 뒤로 정렬
                                } else {
                                    return -1; // null은 뒤로 정렬
                                }
                            } else if (sortCriteria === 'stdname') {
                                // 이름을 문자열로 비교
                                return a.stdname.localeCompare(b.stdname);
                            }
                            // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                            return 0;
                        })
                        .map((doc) => {
                            return(
                                <div className="body_area_mobile" key={doc.id}>
                                    <div className="name">{doc.stdname}</div>
                                    <div className="seat">
                                        <div className="seatno">{doc.seatno}</div>
                                    </div>
                                    <div className="enter">{doc.entertimemobile}</div>
                                    {doc.exittimeprint !== "" ? (
                                        <div className="exit">{doc.exittimemobile}</div>
                                    ) : (
                                        <div className="exit">
                                            <button className="exit" 
                                                onClick={()=>{
                                                    Swal.fire({
                                                        icon: "question",
                                                        title: "퇴실 처리",
                                                        text: "퇴실처리 하시겠습니까?",
                                                        showCancelButton: true,
                                                        confirmButtonText: "퇴실",
                                                        cancelButtonText: "취소",
                                                    }).then((res) => {
                                                        if (res.isConfirmed) {
                                                            checkProcess("checkout",doc.id);
                                                        }
                                                    });
                                                }}
                                            >퇴실처리</button>
                                        </div>
                                    )}
                                    <img src={Etc} alt='더보기' style={{width:'2.5rem',height:'2.5rem'}} 
                                        onClick={(e) => handleEtcClick(e, doc.id)}
                                    />
                                    {showMenu === doc.id && (
                                        <div 
                                            ref={menuRef}
                                            className="etc_area"
                                            style={{
                                                position: 'absolute',
                                                top: `${menuPosition.top}px`,
                                                right: `${menuPosition.right}px`,
                                                zIndex: 1000,
                                            }}
                                        >
                                            <button
                                                className="del mod"
                                                onClick={() => {
                                                    attendInfoMode("attendinfomod", doc.id);
                                                    setShowMenu(null);
                                                }}
                                            >
                                                <img src={EtcMod} alt='수정' style={{width:'1.5rem',height:'1.5rem'}} />
                                                <div className="text_mod">입실시간 수정</div>
                                            </button>
                                            <button 
                                                className="del"
                                                onClick={() => {
                                                    Swal.fire({
                                                        icon: "question",
                                                        title: "출석데이터 삭제",
                                                        text: "출석데이터 삭제를 하시겠습니까?",
                                                        showCancelButton: true,
                                                        confirmButtonText: "삭제",
                                                        cancelButtonText: "취소",
                                                    }).then((res) => {
                                                        if (res.isConfirmed) {
                                                            attendDataDel(doc.id);
                                                            setShowMenu(null);
                                                        }
                                                    });
                                                }}
                                            >
                                                <img src={EtcDel} alt='삭제' style={{width:'1.5rem',height:'1.5rem'}} />
                                                <div className="text_mod del">삭제</div>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )
                        })
                    } 
                </div>
                <div style={{display:'flex',justifyContent:'center',marginTop:'5rem',marginBottom:'2rem'}}>
                    <button className="more" onClick={loadMoreData}>더보기</button>
                </div>
            </div>
        </>
    );
}

export default AttendMng;