import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { doc, updateDoc, setDoc, getDoc } from "firebase/firestore";
import BtnClose from '../images/buttons/close.png';
// import BtnDown from '../images/buttons/chevron-down.png';
import Swal from "sweetalert2";

function PopUp(props) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const popUpName = state.popupName;
    const colId = state.colId;
    const val = state.val;

    const [registerComment, setRegisterComment] = useState(val);
    const [point, setPoint] = useState(0);
    const [registerUsername, setRegisterUsername] = useState("");
    const [seatNo, setSeatNo] = useState("");
    const [registerTitle, setRegisterTitle] = useState("");
    const [userData, setUserData] = useState([]);

    const [enyear, setEnyear] = useState("");
    const [enmonth, setEnmonth] = useState("");
    const [enday, setEnday] = useState("");
    const [enhour, setEnhour] = useState("");
    const [enmin, setEnmin] = useState("");

    const [exyear, setExyear] = useState("");
    const [exmonth, setExmonth] = useState("");
    const [exday, setExday] = useState("");
    const [exhour, setExhour] = useState("");
    const [exmin, setExmin] = useState("");

    const callDb = useCallback(async () => {
        const docRef = doc(db, "users", colId);
        const docSnap = await getDoc(docRef);

        setRegisterUsername(docSnap.data().username);
        setSeatNo(docSnap.data().seatno);
    }, [colId])

    const callDbAttendance = useCallback(async () => {
        const docRef = doc(db, "attendance", colId);
        const docSnap = await getDoc(docRef);

        setUserData(docSnap.data());

        const stseconds = docSnap.data().entertime.seconds;
        const stmilliseconds = stseconds * 1000 + Math.floor(docSnap.data().entertime.nanoseconds / 1e6);
        
        const startDate = new Date(stmilliseconds);
        setEnyear(startDate.getFullYear());
        setEnmonth(startDate.getMonth()+1);
        setEnday(startDate.getDate());
        setEnhour(startDate.getHours());
        setEnmin(startDate.getMinutes());

        if(docSnap.data().exittime !== ""){
            const edseconds = docSnap.data().exittime.seconds;
            const edmilliseconds = edseconds * 1000 + Math.floor(docSnap.data().exittime.nanoseconds / 1e6);
            
            const endDate = new Date(edmilliseconds);
            setExyear(endDate.getFullYear());
            setExmonth(endDate.getMonth()+1);
            setExday(endDate.getDate());
            setExhour(endDate.getHours());
            setExmin(endDate.getMinutes());
        }
    }, [colId])

    useEffect(() => {
        if(popUpName === "specialSch" || popUpName === "pointplus" || popUpName === "pointminus"){
            callDb();
        } else if(popUpName === "attendinfomod"){
            callDbAttendance();
        }
    }, [callDb, callDbAttendance, popUpName])
    
    const saveComment = async (id, stateVal) => {
        try{
            const docRef = doc(db, "attendance", id)
            await updateDoc(docRef, {
                unauthcomment: stateVal
            });
            navigate(-1);
        }catch(error){
            console.log(error.message);
        }
    }

    const delComment = async (id) => {
        try{
            const docRef = doc(db, "attendance", id)
            await updateDoc(docRef, {
                unauthcomment: ""
            });
            navigate(-1);
        }catch(error){
            console.log(error.message);
        }
    }

    const savePoint = async (point, comment) => {
        const currentTimestamp = new Date().getTime();
        const docId = colId+currentTimestamp;

        const itemDate = new Date();
        const itemYear = itemDate.getFullYear();
        const itemMonth = itemDate.getMonth() + 1;
        const itemDay = itemDate.getDate();
        const itemHours = itemDate.getHours();
        const itemMinutes = itemDate.getMinutes();

        if(popUpName === "pointplus") {
            try{
                const ref1 = doc(db, "pointhistory", docId)
                await setDoc(ref1, {
                        userphone: colId,
                        username: val,
                        pointplus: point,
                        pointminus: 0,
                        comment: comment,
                        regtime: currentTimestamp,
                        regtimedisplay: itemYear+"년 "+itemMonth+"월 "+itemDay+"일 "+itemHours+"시 "+itemMinutes+"분",
                        groupid: 'sd3',
                    })
    
                const ref2 = doc(db, "users", colId)
                const docSnapshot = await getDoc(ref2);
    
                if (docSnapshot.exists()) {
                    const currentPointplus = docSnapshot.data().pointplus;
                    const updatedPointplus = currentPointplus + point;
    
                    await updateDoc(ref2, {
                        pointplus: updatedPointplus,
                    });
                }
                navigate(-1);
            }catch(error){
                console.log(error.message);
            }
        } else {
            try{
                const ref1 = doc(db, "pointhistory", docId)
                await setDoc(ref1, {
                        userphone: colId,
                        username: val,
                        pointplus: 0,
                        pointminus: point,
                        comment: comment,
                        regtime: currentTimestamp,
                        regtimedisplay: itemYear+"년 "+itemMonth+"월 "+itemDay+"일 "+itemHours+"시 "+itemMinutes+"분",
                        groupid: 'sd3',
                    })
    
                const ref2 = doc(db, "users", colId)
                const docSnapshot = await getDoc(ref2);
    
                if (docSnapshot.exists()) {
                    const currentPointminus = docSnapshot.data().pointminus;
                    const updatedPointminus = currentPointminus + point;
    
                    await updateDoc(ref2, {
                        pointminus: updatedPointminus,
                    });
                }
                navigate(-1);
            }catch(error){
                console.log(error.message);
            }
        }
    }

    const saveSpecialSch = async (title) => {
        const currentTimestamp = new Date().getTime();
        const docName = colId+registerUsername+currentTimestamp;
        const docRef = doc(db, "absence", docName);

        try{
            await setDoc(docRef, {
                userid: "",
                username: registerUsername,
                userphone: colId,
                daystart: val,
                dayend: val,
                title: registerTitle,
                confirmyn: "Y",
                groupid: "sd3",
            })
            navigate(-1);
        }catch(error){
            console.log(error.message);
        }
    }

    function isNumericString(props) {
        // 정규식을 사용하여 문자열이 숫자로만 이루어져 있는지 확인
        const numericRegex = /^[0-9]+$/;
        return numericRegex.test(props);
    }

    const saveAttendInfoMod = async () => {
        const docRef = doc(db, "attendance", colId);
        if(userData.exittime !== ""){
            if(isNumericString(enmonth) === true && isNumericString(enday) === true &&
                isNumericString(enhour) === true && isNumericString(enmin) === true &&
                isNumericString(exmonth) === true && isNumericString(exday) === true &&
                isNumericString(exhour) === true && isNumericString(exmin) === true
            ){
                const Enmonth = String(enmonth).padStart(2, '0'); // 월 (0부터 시작하므로 +1 필요)
                const Enday = String(enday).padStart(2, '0'); // 일
                const Enhour = String(enhour).padStart(2, '0');
                const Enmin = String(enmin).padStart(2, '0');

                const Exmonth = String(exmonth).padStart(2, '0'); // 월 (0부터 시작하므로 +1 필요)
                const Exday = String(exday).padStart(2, '0'); // 일
                const Exhour = String(exhour).padStart(2, '0');
                const Exmin = String(exmin).padStart(2, '0');
                // Date 객체 생성
                const modDateEnter = new Date(enyear, enmonth-1, enday, enhour, enmin);
                const modDateExit = new Date(exyear, exmonth-1, exday, exhour, exmin);

                try{
                    await updateDoc(docRef, {
                        attenddate: `${enyear}${Enmonth}${Enday}`,
                        entertime: modDateEnter,
                        entertimeprint: enyear+"년 "+Enmonth+"월 "+Enday+"일 "+Enhour+"시 "+Enmin+"분",
                        exittime: modDateExit,
                        exittimeprint: exyear+"년 "+Exmonth+"월 "+Exday+"일 "+Exhour+"시 "+Exmin+"분",
                    })
                    
                    Swal.fire({
                        icon: "success",
                        title: "수정 완료",
                        text: "출석데이터 수정이 완료되었습니다",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                            navigate(-1);
                        }
                    });
                }catch(error){
                    console.log(error.message);
                }
            }else{
                Swal.fire({
                    icon: "error",
                    title: "수정 실패",
                    text: "숫자만 입력 가능합니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });
            }
        } else {
            if(isNumericString(enmonth) === true && isNumericString(enday) === true &&
                isNumericString(enhour) === true && isNumericString(enmin) === true 
            ){
                const Enmonth = String(enmonth).padStart(2, '0'); // 월 (0부터 시작하므로 +1 필요)
                const Enday = String(enday).padStart(2, '0'); // 일
                const Enhour = String(enhour).padStart(2, '0');
                const Enmin = String(enmin).padStart(2, '0');
                // Date 객체 생성
                const modDateEnter = new Date(enyear, enmonth-1, enday, enhour, enmin);

                try{
                    await updateDoc(docRef, {
                        attenddate: `${enyear}${Enmonth}${Enday}`,
                        entertime: modDateEnter,
                        entertimeprint: enyear+"년 "+Enmonth+"월 "+Enday+"일 "+Enhour+"시 "+Enmin+"분",
                    })
                    
                    Swal.fire({
                        icon: "success",
                        title: "수정 완료",
                        text: "출석데이터 수정이 완료되었습니다",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                            navigate(-1);
                        }
                    });
                }catch(error){
                    console.log(error.message);
                }
            }else{
                Swal.fire({
                    icon: "error",
                    title: "수정 실패",
                    text: "숫자만 입력 가능합니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });
            }
        }
    }

    return (
        <div className="modal-overlay">
            {popUpName === 'comment' ? (
                <div style={{width:"25rem",height:"13.75rem",borderRadius:"0.625rem",backgroundColor:"#FFFFFF",
                        border:"#E4E4E4 1px solid",display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <div style={{display:'flex',justifyContent:'flex-end',width:"100%",marginTop:"1.875rem"}}>
                        <img src={BtnClose} style={{width:"1.5rem",height:"1.5rem",marginRight:"1.875rem",cursor:"pointer"}} alt='닫기'
                            onClick={()=>navigate(-1)}
                        ></img>
                    </div>
                    <div style={{width:"19.38rem",color:"#000000",fontWeight:600,fontSize:"1.125rem",marginBottom:"1.25rem"}}>비고 내용입력</div>
                    <input id="_comment" 
                        type="text" 
                        placeholder="내용" 
                        style={{width:"19.38rem",height:"2.5rem",color:"#B1B6C0",borderBottom:"#C1C1C1 1px solid",
                                fontWeight:400,fontSize:"1.125rem"}}
                        value={registerComment}
                        onChange={(e)=>{
                            setRegisterComment(e.target.value);
                        }}
                    ></input>
                    <div style={{width:"19.38rem",display:'flex',justifyContent:'center',marginTop:"1.25rem"}}>
                        <button style={{width:"5rem",height:"2.5rem",border:"#E9E9E9 1px solid",color:"#FF594F",
                                        borderRadius:"3.125rem",marginRight:"0.625rem",fontWeight:500,fontSize:"0.875rem"}}
                                onClick={()=>{delComment(colId)}}
                        >삭제</button>
                        <button style={{width:"5rem",height:"2.5rem",borderRadius:"3.125rem",backgroundColor:"#11167B",
                                        color:"#FFFFFF",fontWeight:500,fontSize:"0.875rem"}}
                                onClick={()=>{saveComment(colId,registerComment)}}
                        >저장</button>
                    </div>
                </div>
            ) : (
                popUpName === "pointplus" || popUpName === "pointminus" ? (
                    <div className="popup_point_wrap">
                        <div className="close_area">
                            <img src={BtnClose} className="close" alt='닫기'
                                onClick={()=>navigate(-1)}
                            ></img>
                        </div>
                        <div style={{display:'flex',alignItems:'center',width:'100%',marginBottom:'2rem'}}>
                            <div className="username">{registerUsername}</div>
                            <div className="seatno">{seatNo}</div>
                        </div>
                        <div style={{marginBottom:"1.5rem"}}>
                            <div className="subtitle" style={{color:popUpName === "pointplus" ? "#0F5EFE" : "#FF594F"}}>{popUpName === "pointplus" ? "상점" : "벌점"}</div>
                            <input id="_point" 
                                type="text" 
                                placeholder="숫자만 입력(예:10)" 
                                onChange={(e)=>{
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    setPoint(parseInt(onlyNumber, 10))
                                }}
                            ></input>
                        </div>
                        <div>
                            <div className="subtitle">비고</div>
                            <input id="_comment" 
                                type="text" 
                                placeholder="내용" 
                                onChange={(e)=>{
                                    setRegisterComment(e.target.value);
                                }}
                            ></input>
                        </div>
                        <div style={{width:"100%",display:'flex',justifyContent:'center',marginTop:'2rem'}}>
                            <button className="save" onClick={()=>{savePoint(point,registerComment)}}
                            >저장</button>
                        </div>
                    </div>
                ) : (
                    popUpName === "specialSch" ? (
                        <div className="popup_specialsch_wrap">
                            <div className="close_area">
                                <img src={BtnClose} className="close" alt='닫기'
                                    onClick={()=>navigate(-1)}
                                ></img>
                            </div>
                            <div className="title">특별일정 입력</div>
                            <input id="_spcschtitle" 
                                type="text" 
                                placeholder="내용" 
                                onChange={(e)=>{
                                    setRegisterTitle(e.target.value);
                                }}
                            ></input>
                            <div style={{width:"100%",display:'flex',justifyContent:'center',marginTop:'2rem'}}>
                                <button className="save" onClick={()=>{saveSpecialSch(registerTitle)}}
                                >저장</button>
                            </div>
                        </div>
                    ) : (
                        popUpName === "attendinfomod" ? (
                            <div className="popup_attendinfo_wrap">
                                <div className="close_area">
                                    <img src={BtnClose} className="close" alt='닫기'
                                        onClick={()=>navigate(-1)}
                                    ></img>
                                </div>
                                <div style={{width:'100%',display:'flex',alignItems:'center',marginBottom:'2rem'}}>
                                    <div className="username">{userData.stdname}</div>
                                    <div className="seatno">{userData.seatno}</div>
                                </div>
                                <div style={{width:'100%'}}>
                                    <div className="title">입실 시간</div>
                                    <div className="timeinfo">
                                        <div>{enyear}년</div>
                                        <input id="_attendMonEn" 
                                            type="number" 
                                            placeholder="월" 
                                            value={enmonth}
                                            onChange={(e)=>{
                                                setEnmonth(e.target.value);
                                            }}
                                        ></input>
                                        <div className="timeunit">월</div>
                                        <input id="_attendDayEn" 
                                            type="number" 
                                            placeholder="일" 
                                            value={enday}
                                            onChange={(e)=>{
                                                setEnday(e.target.value);
                                            }}
                                        ></input>
                                        <div className="timeunit">일</div>
                                        <input id="_attendHourEn" 
                                            type="number" 
                                            placeholder="시" 
                                            value={enhour}
                                            onChange={(e)=>{
                                                setEnhour(e.target.value);
                                            }}
                                        ></input>
                                        <div className="timeunit">시</div>
                                        <input id="_attendMinEn" 
                                            type="number" 
                                            placeholder="분" 
                                            value={enmin}
                                            onChange={(e)=>{
                                                setEnmin(e.target.value);
                                            }}
                                        ></input>
                                        <div className="timeunit">분</div>
                                    </div>
                                </div>
                                { userData.exittime !== "" ? (
                                    <div style={{width:'100%',marginTop:'0.94rem'}}>
                                        <div className="title">퇴실 시간</div>
                                        <div className="timeinfo">
                                            <div>{exyear}년</div>
                                            <input id="_attendMonEx" 
                                                type="number" 
                                                placeholder="월" 
                                                value={exmonth}
                                                onChange={(e)=>{
                                                    setExmonth(e.target.value);
                                                }}
                                            ></input>
                                            <div className="timeunit">월</div>
                                        <input id="_attendDayEx" 
                                            type="number" 
                                            placeholder="일" 
                                            value={exday}
                                            onChange={(e)=>{
                                                setExday(e.target.value);
                                            }}
                                        ></input>
                                        <div className="timeunit">일</div>
                                        <input id="_attendHourEx" 
                                            type="number" 
                                            placeholder="시" 
                                            value={exhour}
                                            onChange={(e)=>{
                                                setExhour(e.target.value);
                                            }}
                                        ></input>
                                        <div className="timeunit">시</div>
                                        <input id="_attendMinEx" 
                                            type="number" 
                                            placeholder="분" 
                                            value={exmin}
                                            onChange={(e)=>{
                                                setExmin(e.target.value);
                                            }}
                                        ></input>
                                        <div className="timeunit">분</div>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                                }
                                <div style={{width:"100%",display:'flex',justifyContent:'center',marginTop:'2rem'}}>
                                    <button className="save" onClick={saveAttendInfoMod}
                                    >저장</button>
                                </div>
                            </div>
                        ) : (
                            <div />
                        )
                    )
                )
            )}
        </div>
    );
}

export default PopUp;