import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase_config.js';
import { doc, setDoc, getDocs, updateDoc, where, collection, query } from "firebase/firestore";
import Swal from "sweetalert2";
import ImgSuccess from '../images/buttons/alarm_attndnc.png';
import AlmMobile from '../images/buttons/alarm_mobile.png';

function AttendChk(props) {
  const [isDivVisible, setDivVisible] = useState(false);  //저장완료시 뜨는 문구
  const [studentData, setStudentData] = useState("");
  const [timeData, setTimeData] = useState("");
  
  useEffect(() => {
    if (isDivVisible) {
        const timer = setTimeout(() => {
        setDivVisible(false);
        }, 1000);

        return () => {
        clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 정리
        };
    }
  },[isDivVisible])

  //저장완료됐을때 저장완료 div 보이게 하는 로직
  const handleSaveComplete = () => {
    setDivVisible(true);  
  };

  const register = useCallback(async (props) => {  
    const cardNo = props.substring(0,10);
    var now = new Date();

    var year = now.getFullYear();
    var month = now.getMonth()+1;
    var day = now.getDate();
    var hours = now.getHours();
    var minutes = now.getMinutes();
    // var seconds = now.getSeconds();
    // const nowChkDate = `${year}${month}${day}`;

    const studentsRef = collection(db, "users");
    const q = query(studentsRef, where("stdcardno", "==", cardNo), where("stdcardno", "!=", ""));
    const querySnapshot = await getDocs(q); //users 테이블의 stdcardno 중에 태그한 카드값이 있는지;
    const userData = querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }));

    const sendPushMessage = async (props) => {
      try {
        await fetch('https://fcm.googleapis.com/fcm/send', {
          method: 'POST',
          headers: {
            Authorization: 'key=AAAANosSmKo:APA91bFttuJQY_oGWqvF-WoeOW1X3-rmmrY7T7QldVYzAYO1c3TQo-JK22aYfSeh-_K4UoRNFnm5oO-Yqnj-qZFIOGnXbYxGiXps5SR82pv8l_BsyP5OPwdHXpWzKNuy3nw1J11RITOj', // Firebase 콘솔에서 얻은 서버 키
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            to: props.token,
            notification: props.notification
          })
        });
        console.log('(카드)푸시 메시지를 성공적으로 보냈습니다.');
      } catch (error) {
        console.error('푸시 메시지 보내기 실패:', error);
      }
    }
    
    if (querySnapshot.docs.length !== 0) {
      const attRef = collection(db, "attendance");
      const q = query(attRef, where("stdcardno", "==", cardNo), where("completeyn", "==", "N"));
      const qSnapshot = await getDocs(q); //attendance 테이블의 오늘 출석한 stdcardno 중에 태그한 카드값이 있는지;

      if (qSnapshot.docs.length !== 0) {
        const attData = qSnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        }));
        
        const elapsedMin = Math.round((now.getTime() - attData[0].entertime.toDate())/1000/60);
        console.log("측정시간: ", elapsedMin);

        if(elapsedMin > 0 && attData[0].exittime === ""){
          try{
              const docRef = doc(db, "attendance", attData[0].id);
              // 초 단위는 일단 뺌 23.08.23
              await updateDoc(docRef, {
                completeyn: 'Y',
                exittime: now,
                // exittimeprint: year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분 "+seconds+"초"
                exittimeprint: year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분"
              });
              // setStudentData(attData[0].stdname+"님 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분 "+seconds+"초 퇴실완료");
              // setStudentData(attData[0].stdname+"님 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분 퇴실완료");
              setStudentData(attData[0].stdname+"님 퇴실완료");
              setTimeData(year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분")
          } catch(error){
              console.log(error.message);
          }

          //push알람 로직
          const payload = {
            notification: {
              title: '출석정보',
              body: month+"월 "+day+"일 "+hours+"시 "+minutes+"분 퇴실완료",
            },
            token: userData[0].token,
          };

          sendPushMessage(payload);
          handleSaveComplete();
        } else{
          Swal.fire({
              icon: "error",
              title: "출결 실패",
              text: "입실 1분 후 부터 퇴실 가능합니다.",
              //showCancelButton: true,
              confirmButtonText: "확인",
              //cancelButtonText: "취소",
          }).then((res) => {
              /* Read more about isConfirmed, isDenied below */
              if (res.isConfirmed) {
                  //확인 요청 처리
              }
          });
        }
      } else {
        try{     
            await setDoc(doc(db, "attendance", userData[0].userphone+year+month+day+hours+minutes),{
                stdname: userData[0].username,
                stdphone: userData[0].userphone,
                stdcardno: cardNo,
                stdid: userData[0].userid,
                // entertimeprint: year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분 "+seconds+"초",
                entertimeprint: year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분",
                entertime: now,
                exittimeprint: '',
                exittime: '',
                attenddate: `${year}${month}${day}`,
                completeyn: 'N',
                unauthabsence: 'N',
                unauthregtime: '',
                unauthdate: '',
                unauthcomment: '',
                groupid: 'sd3',
            });
            // setStudentData(data[0].stdname+"님 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분 "+seconds+"초 입실완료");
            // setStudentData(userData[0].username+"님 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분 입실완료");
            setStudentData(userData[0].username+"님 입실완료");
            setTimeData(year+"년 "+month+"월 "+day+"일 "+hours+"시 "+minutes+"분");
            //window.location.reload(false);  //페이지 refresh
        } catch(error) {
            console.log("에러메세지:", error.message);
        }

        //push알람 로직
        const payload = {
          notification: {
            title: '출석정보',
            body: month+"월 "+day+"일 "+hours+"시 "+minutes+"분 입실완료",
          },
          token: userData[0].token,
        };

        sendPushMessage(payload);
        handleSaveComplete();
      }
    } 
  },[])
  
  useEffect(() => {
    register(props.rfidData);
  },[props.rfidData, register])

  return (
    <>
      <div className="content_wrap">
        <div className="sub_title">출석체크(카드)</div>
        {isDivVisible && 
          <>
            <div className="overlay"/>
            <div className="save_complete">
              <img src={ImgSuccess} style={{width:"64px",height:"64px",marginBottom:"30px"}} alt='완료' />
              <div style={{color:"#000000",fontWeight:500,fontSize:"22px"}}>{studentData}</div>
              <div style={{color:"#BEBEBE",fontWeight:500,fontSize:"14px"}}>{timeData}</div>
            </div>
          </>
        }
      </div>
      {/* 태블릿 영역 */}
      <div className="content_wrap_tablet">
        <div className="sub_title">출석체크(전화번호)</div>
        {isDivVisible && 
          <>
            <div className="overlay"/>
            <div className="save_complete">
              <img src={ImgSuccess} style={{width:"64px",height:"64px",marginBottom:"30px"}} alt='완료' />
              <div style={{color:"#000000",fontWeight:500,fontSize:"22px"}}>{studentData}</div>
              <div style={{color:"#BEBEBE",fontWeight:500,fontSize:"14px"}}>{timeData}</div>
            </div>
          </>
        }
      </div>
      {/* 모바일 영역 */}
      <div className="content_wrap_mobile">
        <div className="no_provide">
            <img src={AlmMobile} style={{width:"64px",height:"64px",marginBottom:"30px"}} alt='알림' />
            <div>스마트폰에서</div>
            <div>지원하지 않는 화면입니다.</div>
        </div>
      </div>
    </>
  );
}

export default AttendChk;