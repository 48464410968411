import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { doc, getDoc, updateDoc, setDoc, Timestamp } from "firebase/firestore";
import Swal from "sweetalert2";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import BtnClose from '../images/buttons/close.png';
import BtnCalendar from '../images/buttons/calendar.png';

function NewModSpecialSch(props) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const colId = state.colId;

    const [registerUsername, setRegisterUsername] = useState("");
    const [registerUserphone, setRegisterUserphone] = useState("");
    const [registerTitle, setRegisterTitle] = useState("");

    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
    const [showCalendarStart, setShowCalendarStart] = useState(false);  //달력 보이기 기능
    const [showCalendarEnd, setShowCalendarEnd] = useState(false);  //달력 보이기 기능
    const [dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("");
    const [selDateStart, setSelDateStart] = useState("");
    const [selDateEnd, setSelDateEnd] = useState("");
    const [dateStartMobile, setDateStartMobile] = useState("");
    const [dateEndMobile, setDateEndMobile] = useState("");
    const calendarRef = useRef(null);
    
    const callDb = useCallback(async () => {
        const docRef = doc(db, "absence", colId);
        const docSnap = await getDoc(docRef);

        setRegisterUsername(docSnap.data().username);
        setRegisterUserphone(docSnap.data().userphone);
        setRegisterTitle(docSnap.data().title);
        
        const startScheDate = new Date(docSnap.data().daystart);
        const startYear = startScheDate.getFullYear().toString();
        const startMonth = (startScheDate.getMonth()+1).toString();
        const startDay = startScheDate.getDate().toString();
        setDateStart(startYear+" 년 "+startMonth+" 월 "+startDay+" 일");
        setDateStartMobile(startYear+"/"+startMonth+"/"+startDay);
        setSelDateStart(docSnap.data().daystart);

        const endScheDate = new Date(docSnap.data().dayend);
        const endYear = endScheDate.getFullYear().toString();
        const endMonth = (endScheDate.getMonth()+1).toString();
        const endDay = endScheDate.getDate().toString();
        setDateEnd(endYear+" 년 "+endMonth+" 월 "+endDay+" 일")
        setDateEndMobile(endYear+"/"+endMonth+"/"+endDay);
        setSelDateEnd(docSnap.data().dayend);
    }, [colId])

    useEffect(() => {
        if(colId !== "new"){
            callDb();
        }
    }, [callDb, colId])

    const saveSpecialSch = async () => {
        if(colId !== "new"){
            const ref = doc(db, "absence", colId);

            try{
                await updateDoc(ref, {
                    username: registerUsername,
                    userphone: registerUserphone,
                    daystart: selDateStart,
                    dayend: selDateEnd,
                    title: registerTitle,
                })
                
                Swal.fire({
                    icon: "success",
                    title: "편집 완료",
                    // text: "",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                        navigate("/specialschmng");
                    }
                });
            }catch(error){
                console.log(error)
            }
        }else if(registerUsername !== "" && registerUserphone !== "" && selDateStart !== "" &&
                selDateEnd !== "" && registerTitle !== ""){
            const currentTimestamp = new Date().getTime();
            const docName = registerUserphone+registerUsername+currentTimestamp;
            const docRef = doc(db, "absence", docName);

            try{
                await setDoc(docRef, {
                    groupid: "sd3",
                    userid: "",
                    username: registerUsername,
                    userphone: registerUserphone,
                    daystart: selDateStart,
                    dayend: selDateEnd,
                    title: registerTitle,
                    confirmyn: "Y",
                    regtime: currentTimestamp,
                })
                
                Swal.fire({
                    icon: "success",
                    title: "등록 완료",
                    // text: "",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                        navigate("/specialschmng");
                    }
                });
            }catch(error){
                console.log(error)
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집 실패",
                text: "빈 값이 없어야 합니다.",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        }
    }

    function ViewCalendarStart() {
        const currentDate = new Date(); // 현재 날짜로 설정
      
        return (
            <>
                {showCalendarStart && (
                    <div className="sliding_calendar" 
                        style={{ top: calendarPosition.top, left: calendarPosition.left }}
                        ref={calendarRef}
                    >
                        <Calendar
                            onChange={(e)=>{
                                const timestampDate = Timestamp.fromDate(e);
                                const scheDate = new Date(timestampDate.toMillis());
                                const Year = scheDate.getFullYear().toString();
                                const Month = (scheDate.getMonth()+1).toString();
                                const Day = scheDate.getDate().toString();
                                setDateStart(Year+" 년 "+Month+" 월 "+Day+" 일");
                                setDateStartMobile(Year+"/"+Month+"/"+Day);
                                setSelDateStart(timestampDate.toMillis());
                                setShowCalendarStart(!showCalendarStart);
                            }}
                            defaultActiveStartDate={currentDate}
                        />
                    </div>
                )}
            </>
        );
    }

    function ViewCalendarEnd() {
        const currentDate = new Date(); // 현재 날짜로 설정
      
        return (
            <>
                {showCalendarEnd && (
                    <div className="sliding_calendar"
                        style={{ top: calendarPosition.top, left: calendarPosition.left }}
                        ref={calendarRef}
                    >
                        <Calendar
                            onChange={(e)=>{
                                const timestampDate = Timestamp.fromDate(e);
                                const scheDate = new Date(timestampDate.toMillis());
                                const Year = scheDate.getFullYear().toString();
                                const Month = (scheDate.getMonth()+1).toString();
                                const Day = scheDate.getDate().toString();
                                setDateEnd(Year+" 년 "+Month+" 월 "+Day+" 일");
                                setDateEndMobile(Year+"/"+Month+"/"+Day);
                                setSelDateEnd(timestampDate.toMillis());
                                setShowCalendarEnd(!showCalendarEnd);
                            }}
                            defaultActiveStartDate={currentDate}
                        />
                    </div>
                )}
            </>
        );
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content" style={{height:"43.75rem"}}>
                <div style={{display:'flex',justifyContent:'flex-end',width:"100%",marginTop:"3.125rem"}}>
                    <img src={BtnClose} style={{width:"1.5rem",height:"1.5rem",marginRight:"3.125rem",cursor:"pointer"}} alt='닫기'
                        onClick={()=>navigate("/specialschmng")}
                    ></img>
                </div>
                <div style={{width:"27.5rem",fontWeight:500,fontSize:"1.625rem",marginBottom:"2.5rem"}}>결석일정 편집</div>
                <div className="unit_title">이름</div>
                <input id="_username" 
                    type="text" 
                    placeholder="수강생 이름" 
                    value={registerUsername}
                    onChange={(e)=>{setRegisterUsername(e.target.value);}}
                ></input>
                <div className="unit_title">전화번호</div>
                <input id="_userphone" 
                    type="text" 
                    placeholder="수강생 전화번호" 
                    value={registerUserphone}
                        onChange={(e)=>{
                        const { value } = e.target
                        const onlyNumber = value.replace(/[^0-9]/g, '')
                        setRegisterUserphone(onlyNumber);
                    }}
                ></input>
                <div style={{width:"27.5rem",display:'flex',justifyContent:'space-between',marginBottom:"1.25rem"}}>
                    <div style={{width:"12.5rem"}}>
                        <div className="unit_title" style={{width:"100%"}}>시작일자</div>
                        <div className="calendar_view" onClick={(e)=>{ 
                                const { top, left, height } = e.target.getBoundingClientRect();
                                const newTop = top + height; // 원하는 위치 조절
                                const newLeft = left; // 원하는 위치 조절
                                setCalendarPosition({ top: newTop, left: newLeft });
                                if(showCalendarEnd === true){
                                    setShowCalendarEnd(false);
                                }
                                setShowCalendarStart(!showCalendarStart);
                        }}>
                            <div>{dateStart}</div>
                            <img src={BtnCalendar} className="button_img" alt='달력'></img>
                        </div>
                    </div>
                    <div style={{width:"12.5rem"}}>
                        <div className="unit_title" style={{width:"100%"}}>종료일자</div>
                        <div className="calendar_view" onClick={(e)=>{ 
                                const { top, left, height } = e.target.getBoundingClientRect();
                                const newTop = top + height; // 원하는 위치 조절
                                const newLeft = left; // 원하는 위치 조절
                                setCalendarPosition({ top: newTop, left: newLeft });
                                if(showCalendarStart === true){
                                    setShowCalendarStart(false);
                                }
                                setShowCalendarEnd(!showCalendarEnd);
                        }}>
                            <div>{dateEnd}</div>
                            <img src={BtnCalendar} className="button_img" alt='달력'></img>
                        </div>
                    </div>
                </div>
                <div className="unit_title">일정명</div>
                <input id="_schname" 
                    type="text" 
                    placeholder="일정명" 
                    value={registerTitle}
                    onChange={(e)=>{setRegisterTitle(e.target.value);}}
                ></input>
                <div style={{width:"27.5rem",height:"4rem",borderRadius:"3.125rem",backgroundColor:"#11167B",marginTop:"1.875rem",
                            textAlign:'center',color:"#FFFFFF",fontWeight:500,fontSize:"1.125rem",lineHeight:"4rem",
                            cursor:'pointer'}}
                    onClick={saveSpecialSch}
                >완료</div>
            </div>
            {/* 태블릿 영역 */}
            <div className="modal-content_tablet" style={{maxHeight:"43.75rem"}}>
                <div style={{display:'flex',justifyContent:'flex-end',width:"100%",marginTop:"3.125rem"}}>
                    <img src={BtnClose} style={{width:"1.5rem",height:"1.5rem",marginRight:"3.125rem",cursor:"pointer"}} alt='닫기'
                        onClick={()=>navigate("/specialschmng")}
                    ></img>
                </div>
                <div style={{width:"75%",fontWeight:500,fontSize:"1.625rem",marginBottom:"2.5rem"}}>결석일정 편집</div>
                <div className="unit_title">이름</div>
                <div style={{width:"75%",height:"3.125rem",marginBottom:"0.625rem"}}>
                    <input id="_username" 
                        type="text" 
                        placeholder="수강생 이름" 
                        value={registerUsername}
                        onChange={(e)=>{setRegisterUsername(e.target.value);}}
                        style={{width:"100%"}}
                    ></input>
                </div>
                <div className="unit_title">전화번호</div>
                <div style={{width:"75%",height:"3.125rem",marginBottom:"0.625rem"}}>
                    <input id="_userphone" 
                        type="text" 
                        placeholder="수강생 전화번호" 
                        value={registerUserphone}
                            onChange={(e)=>{
                            const { value } = e.target
                            const onlyNumber = value.replace(/[^0-9]/g, '')
                            setRegisterUserphone(onlyNumber);
                        }}
                        style={{width:"100%"}}
                    ></input>
                </div>
                <div style={{width:"75%",display:'flex',justifyContent:'space-between',marginBottom:"1.25rem"}}>
                    <div style={{width:"45%"}}>
                        <div className="unit_title" style={{width:"100%"}}>시작일자</div>
                        <div className="calendar_view" onClick={(e)=>{ 
                                const { top, left, height } = e.target.getBoundingClientRect();
                                const newTop = top + height; // 원하는 위치 조절
                                const newLeft = left; // 원하는 위치 조절
                                setCalendarPosition({ top: newTop, left: newLeft });
                                if(showCalendarEnd === true){
                                    setShowCalendarEnd(false);
                                }
                                setShowCalendarStart(!showCalendarStart);
                        }}>
                            <div>{dateStart}</div>
                            <img src={BtnCalendar} className="button_img" alt='달력'></img>
                        </div>
                    </div>
                    <div style={{width:"45%"}}>
                        <div className="unit_title" style={{width:"100%"}}>종료일자</div>
                        <div className="calendar_view" onClick={(e)=>{ 
                                const { top, left, height } = e.target.getBoundingClientRect();
                                const newTop = top + height; // 원하는 위치 조절
                                const newLeft = left; // 원하는 위치 조절
                                setCalendarPosition({ top: newTop, left: newLeft });
                                if(showCalendarStart === true){
                                    setShowCalendarStart(false);
                                }
                                setShowCalendarEnd(!showCalendarEnd);
                        }}>
                            <div>{dateEnd}</div>
                            <img src={BtnCalendar} className="button_img" alt='달력'></img>
                        </div>
                    </div>
                </div>
                <div className="unit_title">일정명</div>
                <div style={{width:"75%",height:"3.125rem",marginBottom:"0.625rem"}}>
                    <input id="_schname_tab" 
                        type="text" 
                        placeholder="일정명" 
                        value={registerTitle}
                        onChange={(e)=>{setRegisterTitle(e.target.value);}}
                        style={{width:"100%"}}
                    ></input>
                </div>
                <div style={{width:"75%",height:"4rem",borderRadius:"3.125rem",backgroundColor:"#11167B",marginTop:"1.25rem",
                            textAlign:'center',color:"#FFFFFF",fontWeight:500,fontSize:"1.125rem",lineHeight:"4rem",
                            cursor:'pointer',marginBottom:"1.875rem"}}
                    onClick={saveSpecialSch}
                >완료</div>
            </div>
            {/* 모바일 영역 */}
            <div className="modal-content_mobile newmodspecialsch">
                <div style={{display:'flex',justifyContent:'flex-end',width:"100%",marginTop:"1.5rem",paddingRight:"1.5rem"}}>
                    <img src={BtnClose} style={{width:"1.5rem",height:"1.5rem",cursor:"pointer"}} alt='닫기'
                        onClick={()=>navigate("/specialschmng")}
                    ></img>
                </div>
                <div style={{padding:"0rem 3rem 1.5rem 3rem"}}>
                    <div className="title">결석일정 편집</div>
                    <div style={{marginBottom:"1.5rem"}}>
                        <div className="subtitle">이름</div>
                        <input id="_username_moblie" 
                            type="text" 
                            placeholder="수강생 이름" 
                            value={registerUsername}
                            onChange={(e)=>{setRegisterUsername(e.target.value);}}
                        ></input>
                    </div>
                    <div style={{marginBottom:"1.5rem"}}>
                        <div className="subtitle">전화번호</div>
                        <input id="_userphone_mobile" 
                            type="text" 
                            placeholder="수강생 전화번호" 
                            value={registerUserphone}
                            onChange={(e)=>{
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')
                                setRegisterUserphone(onlyNumber);
                            }}
                        ></input>
                    </div>
                    <div style={{marginBottom:"1.5rem"}}>
                        <div className="subtitle">일정 명</div>
                        <input id="_schname_tab_mobile" 
                            type="text" 
                            placeholder="일정 명" 
                            value={registerTitle}
                            onChange={(e)=>{setRegisterTitle(e.target.value);}}
                        ></input>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between',marginBottom:'2rem'}}>
                        <div>
                            <div className="subtitle half">시작일</div>
                            <div className="select_warp" onClick={(e)=>{ 
                                const { top, height } = e.target.getBoundingClientRect();
                                const viewportWidth = window.innerWidth / 2;
                                const newTop = top + height; // 원하는 위치 조절
                                const newLeft = viewportWidth - 175; // 요소의 중앙 위치 계산
                                setCalendarPosition({ 
                                    top: newTop, 
                                    left: newLeft,
                                });
                                
                                if(showCalendarEnd === true){
                                    setShowCalendarEnd(false);
                                }
                                setShowCalendarStart(!showCalendarStart);
                            }}>
                                <div>{dateStartMobile}</div>
                                <img src={BtnCalendar} alt='달력'></img>
                            </div>
                        </div>
                        <div>
                            <div className="subtitle half">종료일</div>
                            <div className="select_warp" onClick={(e)=>{ 
                                const { top, height } = e.target.getBoundingClientRect();
                                const viewportWidth = window.innerWidth / 2;
                                const newTop = top + height; // 원하는 위치 조절
                                const newLeft = viewportWidth - 175; // 요소의 중앙 위치 계산
                                setCalendarPosition({ 
                                    top: newTop, 
                                    left: newLeft,
                                });

                                if(showCalendarStart === true){
                                    setShowCalendarStart(false);
                                }
                                setShowCalendarEnd(!showCalendarEnd);
                            }}>
                                <div>{dateEndMobile}</div>
                                <img src={BtnCalendar} alt='달력'></img>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <button className="complete" onClick={saveSpecialSch}>완료</button>
                    </div>
                </div>
            </div>
            <ViewCalendarStart />
            <ViewCalendarEnd />
        </div>
    );
}

export default NewModSpecialSch;