import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, getDocs, where, orderBy } from "firebase/firestore";
import Swal from "sweetalert2";
import BtnSearch from '../images/buttons/search.png';
import BtnArrowDown from '../images/buttons/arrow_down_grey_16.png'
import PointIcon from '../images/buttons/point_icon_mobile.svg';
import Downward from '../images/buttons/downward_mobile.svg';
import ArrowUpDown from '../images/buttons/arrow_updown_mobile.svg';
import SearchWhite from '../images/buttons/search_white_mobile.svg';
import Etc from '../images/buttons/etc.svg';
import EtcMod from '../images/buttons/etc_mod_mobile.svg';

function PointMng() {
    const navigate = useNavigate();
    const searhNameRef = useRef(null);
    const searhNameRefTablet = useRef(null);
    const searhNameRefMobile = useRef(null);

    const [studentData, setStudentData] = useState([]);
    const [studentDataRaw, setStudentDataRaw] = useState([]);
    const [sortCriteria, setSortCriteria] = useState('totalpoint'); // 초기 정렬 기준은 좌석번호

    const [seasonInfo, setSeasonInfo] = useState([]);   //시즌 정보
    const [selSeason, setSelSeason] = useState("sd3");  //선택 시즌 변수

    const [showMenu, setShowMenu] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, right: 0 });
    const menuRef = useRef(null);

    //시즌 정보 코드
    useEffect(() => {
        async function fetchSeasonInfo() {
            const seasonQ = query(collection(db, "seasoninfo"), orderBy("seq", 'desc'));
            const seasonSnapshot = await getDocs(seasonQ);
    
            const seasonData = seasonSnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });
            setSeasonInfo(seasonData);
        }
    
        fetchSeasonInfo();
    }, []);
    
    const callDb = useCallback(async () => {
        //설문조사 데이터 조회 변수
        const q = query(collection(db, "users"), where("confirmyn", "==", "Y"), 
                        where("auth", "==", 1), where("groupid", "==", selSeason));
        const snapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;
        const histq = query(collection(db, "userhistory"),where("groupid", "==", selSeason),
                    where("membertype", "==", "C"));
        const histsnapshot = await getDocs(histq); 

        const userData = snapshot.docs.concat(histsnapshot.docs).map(doc => {
            return {
                ...doc.data(),
                id: doc.id,
                totalpoint: doc.data().pointplus - doc.data().pointminus,
            };
        });
        
        setStudentData(userData);
        setStudentDataRaw(userData);
    }, [selSeason])

    useEffect(() => {
        callDb();
    }, [callDb])

    function formatPhoneNumber(phoneNumber) {
        // 정규식을 사용하여 숫자만 추출
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    
        // 전화번호 형식으로 변환
        const formatted = cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    
        return formatted;
    }

    //이름조회 input 처리로직
    const handleSearch = (props) => {
        // const nameValue = searhNameRef.current.value;
        const nameValue = props;
        setStudentData(studentDataRaw);
        
        if(nameValue !== ""){
            const filteredCustomer = studentDataRaw.filter(item => item.username.includes(nameValue));
            setStudentData(filteredCustomer);
        }else{
            callDb();
        }
    }

    const pointReg = (popupName, id, val) => {
        if(selSeason === "sd3"){
            navigate("/popup", {
                state: {
                    popupName: popupName,
                    colId: id,
                    val: val,
                },
            })
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    const handleEtcClick = (event, docId) => {
        event.preventDefault();
        const rect = event.target.getBoundingClientRect();
        setMenuPosition({
            top: rect.bottom + window.scrollY,
            right: window.innerWidth - rect.right + window.scrollX
        });
        setShowMenu(docId);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    const toPointHistory = () => {
        navigate("/pointhistory", {
            state: {
                studentdata: studentData,
            },
        })
    }

    const handleSortClick = (criteria) => {
        setSortCriteria(criteria);
    };

    return (
        <>
            <div className="content_wrap">
                <div style={{width:"100%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem",marginBottom:"3.125rem"}}>
                    <div style={{display:'flex'}}>
                        <div className="title_absent">상벌점관리</div>
                        <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                        <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                            onClick={toPointHistory}
                        >상벌점이력</div>
                    </div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRef}
                            style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                        />
                        <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                            onClick={() => {handleSearch(searhNameRef.current.value)}}
                        ></img>
                    </div>
                </div>
                <div style={{width:"100%",marginTop:"1.25rem",marginBottom:"1.25rem",display:'flex',}}>
                    <select className="btn_midside" style={{width:"6.25rem",textAlign:'center'}} 
                            onChange={(e) => {
                                setSelSeason(e.target.value);
                            }}>
                        {seasonInfo.map(season => (
                            <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                        ))}
                    </select>
                    <div className="btn_midside" style={{width:"6.25rem",}} onClick={() => handleSortClick('totalpoint')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='총상벌점순' style={{width:"1rem",height:"1rem"}}/>
                        총상벌점순
                    </div>
                    <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        좌석번호순
                    </div>
                    <div className="btn_midside" style={{width:"5.375rem",marginRight:"0rem"}} onClick={() => handleSortClick('username')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                        이름순
                    </div>
                </div>
                <div style={{width:"100%",height:"2.5rem",display:'flex',justifyContent:'space-between',color:"#A6A7AB",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem"}}>
                    <div style={{width:"6.25rem"}}>이름</div>
                    <div style={{width:"6.25rem"}}>좌석번호</div>
                    <div style={{width:"12.5rem"}}>전화번호</div>
                    <div style={{width:"12.5rem"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"12.5rem",textAlign:'center'}}>상점</div>
                    <div style={{width:"12.5rem",textAlign:'center'}}>벌점</div>
                    <div style={{width:"12.5rem",textAlign:'center'}}>합계</div>
                    <div style={{width:"12.5rem"}}/>
                </div>
                {studentData
                    .sort((a, b) => {
                        if (sortCriteria === 'totalpoint') {
                            return b.totalpoint - a.totalpoint; // 숫자로 비교
                        } else if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'username') {
                            // 이름을 문자열로 비교
                            return a.username.localeCompare(b.username);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        let gender;

                        if(doc.gender === "M"){
                            gender = "남";
                        }else if(doc.gender === "F"){
                            gender = "여";
                        }else{
                            gender = "";
                        }
                        
                        const userPhone = formatPhoneNumber(doc.userphone);
                        const userInfo = doc.school+" / "+doc.grade+" / "+gender;
                        // const point = doc.pointplus - doc.pointminus;

                        return(
                            <div key={doc.id} style={{width:"100%",height:"2.5rem",display:'flex',justifyContent:'space-between',color:"#191919",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem"}}>
                                <div style={{width:"6.25rem",fontWeight:500}}>{doc.username}</div>
                                <div style={{width:"6.25rem"}}>{doc.seatno}</div>
                                <div style={{width:"12.5rem"}}>{userPhone}</div>
                                <div style={{width:"12.5rem"}}>{userInfo}</div>
                                <div style={{width:"12.5rem",textAlign:'center'}}>{doc.pointplus}</div>
                                <div style={{width:"12.5rem",textAlign:'center',color:"#FF5B5B"}}>{doc.pointminus}</div>
                                <div style={{width:"12.5rem",textAlign:'center',color:doc.totalpoint >= 0 ? "#0F5EFE" : "#FF5B5B"}}>{doc.totalpoint}</div>
                                <div style={{width:"12.5rem",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <button className="btn_moddel" style={{color:"#8D8D8D"}} onClick={()=>pointReg("pointplus", doc.id, doc.username)}>상점</button>
                                    <button className="btn_moddel" style={{color:"#FF594F"}} onClick={()=>pointReg("pointminus", doc.id, doc.username)}>벌점</button>
                                </div>
                            </div>
                        )
                    })
                }
                <footer>
                    <div className="copyright" style={{marginTop:"6.25rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{width:"95%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem",marginBottom:"3.125rem"}}>
                    <div style={{display:'flex'}}>
                        <div className="title_absent">상벌점관리</div>
                        <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                        <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                            onClick={toPointHistory}
                        >상벌점이력</div>
                    </div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefTablet}
                            style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                        />
                        <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                            onClick={() => {handleSearch(searhNameRefTablet.current.value)}}
                        ></img>
                    </div>
                </div>
                <div style={{width:"95%",marginTop:"1.25rem",marginBottom:"1.25rem",display:'flex',}}>
                    <select className="btn_midside" style={{width:"6.25rem",textAlign:'center'}} 
                            onChange={(e) => {
                                setSelSeason(e.target.value);
                            }}>
                        {seasonInfo.map(season => (
                            <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                        ))}
                    </select>
                    <div className="btn_midside" style={{width:"6.25rem",}} onClick={() => handleSortClick('totalpoint')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='총상벌점순' style={{width:"1rem",height:"1rem"}}/>
                        총상벌점순
                    </div>
                    <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        좌석번호순
                    </div>
                    <div className="btn_midside" style={{width:"5.375rem",marginRight:"0rem"}} onClick={() => handleSortClick('username')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                        이름순
                    </div>
                </div>
                <div style={{width:"95%",height:"3rem",display:'flex',justifyContent:'space-between',color:"#A6A7AB",fontWeight:400,fontSize:"1rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"3rem"}}>
                    {/* <div style={{width:"140px"}}>이름</div>
                    <div style={{width:"180px"}}>전화번호</div>
                    <div style={{width:"200px"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"100px",textAlign:'center'}}>상점</div>
                    <div style={{width:"100px",textAlign:'center'}}>벌점</div>
                    <div style={{width:"100px",textAlign:'center'}}>합계</div>
                    <div style={{width:"200px"}}/> */}
                    <div style={{width:"10%"}}>이름</div>
                    <div style={{width:"10%"}}>좌석번호</div>
                    <div style={{width:"18%"}}>전화번호</div>
                    <div style={{width:"20%"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"8%",textAlign:'center'}}>상점</div>
                    <div style={{width:"8%",textAlign:'center'}}>벌점</div>
                    <div style={{width:"8%",textAlign:'center'}}>합계</div>
                    <div style={{width:"18%"}}/>
                </div>
                {studentData
                    .sort((a, b) => {
                        if (sortCriteria === 'totalpoint') {
                            return b.totalpoint - a.totalpoint; // 숫자로 비교
                        } else if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'username') {
                            // 이름을 문자열로 비교
                            return a.username.localeCompare(b.username);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        let gender;

                        if(doc.gender === "M"){
                            gender = "남";
                        }else if(doc.gender === "F"){
                            gender = "여";
                        }else{
                            gender = "";
                        }
                        
                        const userPhone = formatPhoneNumber(doc.userphone);
                        const userInfo = doc.school+" / "+doc.grade+" / "+gender;
                        // const point = doc.pointplus - doc.pointminus;

                        return(
                            <div key={doc.id} style={{width:"95%",height:"3rem",display:'flex',justifyContent:'space-between',color:"#191919",fontWeight:400,fontSize:"0.875rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"3rem"}}>
                                {/* <div style={{width:"140px",fontWeight:500}}>{doc.username}</div>
                                <div style={{width:"180px"}}>{userPhone}</div>
                                <div style={{width:"200px"}}>{userInfo}</div>
                                <div style={{width:"100px",textAlign:'center'}}>{doc.pointplus}</div>
                                <div style={{width:"100px",textAlign:'center',color:"#FF5B5B"}}>{doc.pointminus}</div>
                                <div style={{width:"100px",textAlign:'center',color:point >= 0 ? "#0F5EFE" : "#FF5B5B"}}>{point}</div>
                                <div style={{width:"200px",display:'flex',alignItems:'center',justifyContent:'flex-end'}}> */}
                                <div style={{width:"10%",fontWeight:500}}>{doc.username}</div>
                                <div style={{width:"10%"}}>{doc.seatno}</div>
                                <div style={{width:"18%"}}>{userPhone}</div>
                                <div style={{width:"20%"}}>{userInfo}</div>
                                <div style={{width:"8%",textAlign:'center'}}>{doc.pointplus}</div>
                                <div style={{width:"8%",textAlign:'center',color:"#FF5B5B"}}>{doc.pointminus}</div>
                                <div style={{width:"8%",textAlign:'center',color:doc.totalpoint >= 0 ? "#0F5EFE" : "#FF5B5B"}}>{doc.totalpoint}</div>
                                <div style={{width:"18%",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <button className="btn_moddel" style={{color:"#8D8D8D"}} onClick={()=>pointReg("pointplus", doc.id, doc.username)}>상점</button>
                                    <button className="btn_moddel" style={{color:"#FF594F"}} onClick={()=>pointReg("pointminus", doc.id, doc.username)}>벌점</button>
                                </div>
                            </div>
                        )
                    })
                }
                <footer>
                    <div className="copyright" style={{marginTop:"6.25rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile padding_horizonal">
                <div className="sub_title attendmng">
                    <img src={PointIcon} alt='상벌점관리' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                    <div>상벌점관리</div>
                    <div className="notselected" style={{margin:'0rem 0.3rem'}}>·</div>
                    <div className="notselected" onClick={toPointHistory}>상벌점이력</div>
                </div>
                <div style={{display:'flex',justifyContent:'space-between',marginTop:'3.13rem'}}>
                    <div style={{display:'flex'}}>
                        <div className="button_season_sort">
                            <select className="season" 
                                onChange={(e) => {setSelSeason(e.target.value);}}>
                                {seasonInfo.map(season => (
                                    <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                                ))}
                            </select>
                            <img src={Downward} alt='시즌' style={{width:"1rem",height:"1rem"}} />
                        </div>
                        <div style={{width:'0.62rem'}} />
                        <div className="button_season_sort" 
                            onClick={() => {
                                if(sortCriteria === "seatno"){
                                    handleSortClick('username')
                                }else{
                                    handleSortClick('seatno')
                                }
                            }}
                        >
                            {sortCriteria === "seatno" ? "좌석순"
                            : "이름순"
                            }
                            <img src={ArrowUpDown} alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        </div>
                    </div>
                    <div className="search_name_area">
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefMobile}/>
                        <div className="button_area">
                            <img src={SearchWhite} style={{width:'0.9375rem',height:'0.99613rem'}} alt='검색'
                                onClick={() => {handleSearch(searhNameRefMobile.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div className="header_area_mobile_container">
                    <div className="header_area_mobile">
                        <div className="name">이름</div>
                        <div className="seat">좌석</div>
                        <div className="phone">전화번호</div>
                        <div className="personalinfo">학교/학년/성별</div>
                        <div className="point">상점</div>
                        <div className="point">벌점</div>
                        <div className="point">합계</div>
                    </div>
                    {studentData
                        .sort((a, b) => {
                            if (sortCriteria === 'totalpoint') {
                                return b.totalpoint - a.totalpoint; // 숫자로 비교
                            } else if (sortCriteria === 'seatno') {
                                // 좌석번호 숫자를 비교
                                if (a.seatno !== "" && b.seatno !== "") {
                                    return a.seatno - b.seatno;
                                } else if (a.seatno === "") {
                                    return 1; // null은 뒤로 정렬
                                } else {
                                    return -1; // null은 뒤로 정렬
                                }
                            } else if (sortCriteria === 'username') {
                                // 이름을 문자열로 비교
                                return a.username.localeCompare(b.username);
                            }
                            // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                            return 0;
                        })
                        .map((doc) => {
                            let gender;

                            if(doc.gender === "M"){
                                gender = "남";
                            }else if(doc.gender === "F"){
                                gender = "여";
                            }else{
                                gender = "";
                            }
                            
                            const userPhone = formatPhoneNumber(doc.userphone);
                            const userInfo = doc.school+" / "+doc.grade+" / "+gender;

                            return(
                                <div className="body_area_mobile" key={doc.id}>
                                    <div className="name">{doc.username}</div>
                                    <div className="seat">
                                        <div className="seatno">{doc.seatno}</div>
                                    </div>
                                    <div className="phone">{userPhone}</div>
                                    <div className="personalinfo">{userInfo}</div>
                                    <div className="point plus">{doc.pointplus}</div>
                                    <div className="point minus">{doc.pointminus}</div>
                                    <div className="point">{doc.totalpoint}</div>
                                    <img src={Etc} alt='더보기' style={{width:'2.5rem',height:'2.5rem'}} 
                                        onClick={(e) => handleEtcClick(e, doc.id)}
                                    />
                                    {showMenu === doc.id && (
                                        <div 
                                            ref={menuRef}
                                            className="etc_area"
                                            style={{
                                                position: 'absolute',
                                                top: `${menuPosition.top}px`,
                                                right: `${menuPosition.right}px`,
                                                zIndex: 1000,
                                            }}
                                        >
                                            <button
                                                className="del mod"
                                                onClick={() => {
                                                    pointReg("pointplus", doc.id, doc.username);
                                                    setShowMenu(null);
                                                }}
                                            >
                                                <img src={EtcMod} alt='상점' style={{width:'1.5rem',height:'1.5rem'}} />
                                                <div className="text_mod plus">상점</div>
                                            </button>
                                            <button
                                                className="del mod"
                                                onClick={() => {
                                                    pointReg("pointminus", doc.id, doc.username);
                                                    setShowMenu(null);
                                                }}
                                            >
                                                <img src={EtcMod} alt='벌점' style={{width:'1.5rem',height:'1.5rem'}} />
                                                <div className="text_mod del">벌점</div>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default PointMng;