import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, getDocs, where, Timestamp } from "firebase/firestore";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import BtnDown from '../images/buttons/arrow_down.png';
import AttendIcon from '../images/buttons/attend_icon_mobile.svg';

function AbsenceMng() {
    const navigate = useNavigate();
    const calendarRef = useRef(null);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const year = now.getFullYear();
    const month = (now.getMonth()+1).toString();
    const day = now.getDate().toString();
    
    const [displayDate, setDisplayDate] = useState(year+"년 "+month+"월 "+day+"일");
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
    const [showCalendar, setShowCalendar] = useState(false);  //달력 보이기 기능
    const [selectedDate, setSelectedDate] = useState(now.getTime());
    const [selectedTime, setSelectedTime] = useState(now);
    
    //setSelectedDate(year+"년 "+month+"월 "+day+"일");

    const [absenceData, setAbsencedata] = useState([]);
    const [displayUserData, setDisplayUserData] = useState([]);

    const callDb = useCallback(async () => {
        // const now = new Date();
        const year = selectedTime.getFullYear();
        const month = selectedTime.getMonth();
        const date = selectedTime.getDate();

        //학생 데이터
        const stuq = query(collection(db, "users"), where("confirmyn", "==", "Y"), 
            where("auth", "==", 1), where("groupid", "==", "sd3")
        );
        const stusnapshot = await getDocs(stuq);

        const stuData = stusnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        //출석 데이터
        const atdq = query(collection(db, "attendance"), 
                        where("attenddate", "==", String(year)+String(month+1).padStart(2, '0')+String(date).padStart(2, '0')),
                        where("groupid", "==", "sd3"))
        const atdsnapshot = await getDocs(atdq); 
        const atdqueryData = atdsnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        const filteredStuData = stuData.filter(student => {
            // stuData의 userphone이 atdqueryData의 userphone과 일치하는지 확인
            return !atdqueryData.some(attendance => attendance.stdphone === student.userphone);
        });
        
        // filteredStuData를 사용하거나 다른 작업 수행
        setDisplayUserData(filteredStuData);

        //특별일정 데이터
        const absq = query(collection(db, "absence"), 
                        where("daystart", "<=", selectedDate), where("confirmyn", "==", "Y"),
                        where("groupid", "==", "sd3"))
        const abssnapshot = await getDocs(absq); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;
        const absData = abssnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        const filteredAbsData = absData.filter(item => item.dayend >= selectedDate);
        // filteredStuData를 사용하거나 다른 작업 수행
        setAbsencedata(filteredAbsData);
    },[selectedDate, selectedTime])
  
    useEffect(() => {
        callDb();
    },[callDb])
    
    const handleDateClick = (date) => {
        const selYear = date.getFullYear();
        const selMonth = (date.getMonth()+1).toString();
        const selDay = date.getDate().toString();
        const timestampDate = Timestamp.fromDate(date);
        setDisplayDate(selYear+"년 "+selMonth+"월 "+selDay+"일");
        setShowCalendar(!showCalendar);

        setSelectedDate(timestampDate.toMillis())
        setSelectedTime(date);
    };

    function ViewCalendar() {
        const currentDate = new Date(); // 현재 날짜로 설정
      
        return (
            <>
                {showCalendar && (
                    <div className="sliding_calendar" 
                        style={{ top: calendarPosition.top, left: calendarPosition.left }}
                        ref={calendarRef}
                    >
                        <Calendar
                            onChange={handleDateClick}
                            defaultActiveStartDate={currentDate}
                        />
                    </div>
                )}
            </>
        );
    }

    function formatPhoneNumber(phoneNumber) {
        // 정규식을 사용하여 숫자만 추출
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    
        // 전화번호 형식으로 변환
        const formatted = cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    
        return formatted;
    }

    const toAttendMng = () => {
        navigate("/attendmng")
    }

    const specialSchReg = (popupName, id, val) => {
        navigate("/popup", {
            state: {
                popupName: popupName,
                colId: id,
                val: val,
            },
        })
    }

    return (
        <>
            <div className="content_wrap">
                <div style={{display:'flex',width:'100%',marginTop:"1.25rem"}}>
                    <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                        onClick={toAttendMng}
                    >출석관리</div>
                    <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                    <div className="title_absent">결석관리</div>
                </div>
                <div style={{width:"100%",marginTop:"4.375rem",marginBottom:"1.25rem"}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:"16.13rem",height:"4rem",borderRadius:"0.5rem",backgroundColor:"#F6F6F6"}}
                        onClick={(e)=>{ 
                            const { top, left, height } = e.target.getBoundingClientRect();
                            const newTop = top + height; // 원하는 위치 조절
                            const newLeft = left; // 원하는 위치 조절
                            setCalendarPosition({ top: newTop, left: newLeft });
                            setShowCalendar(!showCalendar);
                    }}>
                        <div style={{color:"#0F5EFE",fontWeight:500,fontSize:"1.5rem",marginRight:"0.625rem"}}>{displayDate}</div>
                        <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                    </div>
                </div>
                <ViewCalendar />
                <div style={{width:"100%",height:"2.5rem",display:'flex',color:"#8D8D8D",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#EEEEEE 1px solid",lineHeight:"2.5rem"}}>
                    <div style={{width:"12.5rem"}}>이름</div>
                    <div style={{width:"12.5rem"}}>좌석번호</div>
                    <div style={{width:"12.5rem"}}>전화번호</div>
                    <div style={{width:"12.5rem"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"12.5rem"}}>일정명</div>
                    <div style={{width:"15.63rem"}}>기간</div>
                    <div style={{width:"9.375rem"}}/>
                </div>
                {displayUserData.map((doc) => {
                    const userPhone = formatPhoneNumber(doc.userphone);
                    let gender;

                    if(doc.gender === "M"){
                        gender = "남";
                    }else if(doc.gender === "F"){
                        gender = "여";
                    }else{
                        gender = "";
                    }
                    const userInfo = doc.school+" / "+doc.grade+" / "+gender;

                    let title = "";
                    let period = "";
                    const absence = absenceData.filter(item => item.userphone === doc.userphone);
                    if(absence.length !== 0){
                        const startScheDate = new Date(absence[0].daystart);
                        const startYear = startScheDate.getFullYear().toString();
                        const startMonth = (startScheDate.getMonth()+1).toString();
                        const startDay = startScheDate.getDate().toString();
                        const formattedDateStart = startYear+"/"+startMonth+"/"+startDay;

                        const endScheDate = new Date(absence[0].dayend);
                        const endYear = endScheDate.getFullYear().toString();
                        const endMonth = (endScheDate.getMonth()+1).toString();
                        const endDay = endScheDate.getDate().toString();
                        const formattedDateEnd = endYear+"/"+endMonth+"/"+endDay;

                        period = formattedDateStart+" ~ "+formattedDateEnd;
                        title = absence[0].title;
                    }

                    return (
                        <div key={doc.id} style={{width:"100%",height:"2.5rem",display:'flex',justifyContent:'space-between',
                                                color: absence.length !== 0 ? "#0F5EFE" : "#191919",fontWeight:400,fontSize:"0.9375rem",
                                                borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem"}}>
                            <div style={{width:"12.5rem",fontWeight:500}}>{doc.username}</div>
                            <div style={{width:"12.5rem"}}>{doc.seatno}</div>
                            <div style={{width:"12.5rem"}}>{userPhone}</div>
                            <div style={{width:"12.5rem"}}>{userInfo}</div>
                            <div style={{width:"12.5rem"}}>{title.length > 15 ? title.slice(0, 15) + '...' : title}</div>
                            <div style={{width:"15.63rem"}}>{period}</div>
                            <div style={{width:"9.375rem",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                {/* <button className="btn_moddel" style={{color:"#8D8D8D"}} onClick={()=>newModSpecialSch(doc.id)}>수정</button>
                                <button className="btn_moddel" style={{color:"#FF594F"}} onClick={()=>delSchedule(doc.id)}>삭제</button> */}
                                {absence.length !== 0 ?
                                    <></>
                                    :
                                    <button className="btn_moddel" style={{color:"#FF594F",width:"7.5rem"}}
                                            onClick={()=>specialSchReg("specialSch", doc.userphone, selectedDate)}
                                    >결석일정등록</button> 
                                }
                            </div>
                        </div>
                    );
                })}
                <footer>
                    <div className="copyright" style={{marginTop:"3.125rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{display:'flex',width:"95%",marginTop:"1.25rem"}}>
                    <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                        onClick={toAttendMng}
                    >출석관리</div>
                    <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                    <div className="title_absent">결석관리</div>
                </div>
                <div style={{width:"95%",marginTop:"4.375rem",marginBottom:"1.25rem"}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:"16.13rem",height:"4rem",borderRadius:"0.5rem",backgroundColor:"#F6F6F6"}}
                        onClick={(e)=>{ 
                            const { top, left, height } = e.target.getBoundingClientRect();
                            const newTop = top + height; // 원하는 위치 조절
                            const newLeft = left; // 원하는 위치 조절
                            setCalendarPosition({ top: newTop, left: newLeft });
                            setShowCalendar(!showCalendar);
                    }}>
                        <div style={{color:"#0F5EFE",fontWeight:500,fontSize:"1.5rem",marginRight:"0.625rem"}}>{displayDate}</div>
                        <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                    </div>
                </div>
                <ViewCalendar />
                <div style={{width:"95%",height:"3rem",display:'flex',color:"#A6A7AA",fontWeight:400,fontSize:"1rem",borderBottom:"#EEEEEE 1px solid",lineHeight:"3rem"}}>
                    <div style={{width:"10%"}}>이름</div>
                    <div style={{width:"10%"}}>좌석번호</div>
                    <div style={{width:"15%"}}>전화번호</div>
                    <div style={{width:"20%"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"15%"}}>일정명</div>
                    <div style={{width:"20%"}}>기간</div>
                    <div style={{width:"10%"}} />
                </div>
                {displayUserData.map((doc) => {
                    const userPhone = formatPhoneNumber(doc.userphone);
                    let gender;

                    if(doc.gender === "M"){
                        gender = "남";
                    }else if(doc.gender === "F"){
                        gender = "여";
                    }else{
                        gender = "";
                    }
                    const userInfo = doc.school+" / "+doc.grade+" / "+gender;

                    let title = "";
                    let period = "";
                    const absence = absenceData.filter(item => item.userphone === doc.userphone);
                    if(absence.length !== 0){
                        const startScheDate = new Date(absence[0].daystart);
                        const startYear = startScheDate.getFullYear().toString();
                        const startMonth = (startScheDate.getMonth()+1).toString();
                        const startDay = startScheDate.getDate().toString();
                        const formattedDateStart = startYear+"/"+startMonth+"/"+startDay;

                        const endScheDate = new Date(absence[0].dayend);
                        const endYear = endScheDate.getFullYear().toString();
                        const endMonth = (endScheDate.getMonth()+1).toString();
                        const endDay = endScheDate.getDate().toString();
                        const formattedDateEnd = endYear+"/"+endMonth+"/"+endDay;

                        period = formattedDateStart+" ~ "+formattedDateEnd;
                        title = absence[0].title;
                    }

                    return (
                        <div key={doc.id} style={{width:"95%",height:"3rem",display:'flex',
                        color: absence.length !== 0 ? "#0F5EFE" : "#191919",fontWeight:500,fontSize:"0.875rem",
                        lineHeight:"3rem",alignItems:'center',borderBottom:"#EEEEEE 1px solid"}}>
                            <div style={{width:"10%",fontWeight:500}}>{doc.username}</div>
                            <div style={{width:"10%"}}>{doc.seatno}</div>
                            <div style={{width:"15%"}}>{userPhone}</div>
                            <div style={{width:"20%"}}>{userInfo}</div>
                            <div style={{width:"15%"}}>{title.length > 15 ? title.slice(0, 15) + '...' : title}</div>
                            <div style={{width:"20%"}}>{period}</div>
                            <div style={{width:"10%",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                {absence.length !== 0 ?
                                    <></>
                                    :
                                    <button className="btn_moddel" style={{color:"#FF594F",width:"95%"}}
                                            onClick={()=>specialSchReg("specialSch", doc.userphone, selectedDate)}
                                    >특별일정등록</button> 
                                }
                                
                            </div>
                        </div>
                    );
                })} 
                <footer>
                    <div className="copyright" style={{marginTop:"3.125rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile padding_horizonal">
                <div className="sub_title attendmng">
                    <img src={AttendIcon} alt='출결관리' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                    <div className="notselected" onClick={toAttendMng}>출석관리</div>
                    <div className="notselected" style={{margin:'0rem 0.3rem'}}>·</div>
                    <div>결석관리</div>
                </div>
                <div className="calendar_area"
                    onClick={(e)=>{ 
                        setCalendarPosition({ top: '17rem', left: '1.25rem' });
                        setShowCalendar(!showCalendar);
                }}>
                    <div className="calendar_text">{displayDate}</div>
                    <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                </div>
                <ViewCalendar />
                <div className="header_area_mobile_container">
                    <div className="header_area_mobile">
                        <div className="name">이름</div>
                        <div className="seat">좌석</div>
                        <div className="phone">전화번호</div>
                        <div className="personalinfo">학교/학년/성별</div>
                        <div className="schedule">일정명</div>
                        <div className="term">기간</div>
                    </div>
                    {displayUserData.map((doc) => {
                        const userPhone = formatPhoneNumber(doc.userphone);
                        let gender;

                        if(doc.gender === "M"){
                            gender = "남";
                        }else if(doc.gender === "F"){
                            gender = "여";
                        }else{
                            gender = "";
                        }
                        const userInfo = doc.school+" / "+doc.grade+" / "+gender;

                        let title = "";
                        let period = "";
                        const absence = absenceData.filter(item => item.userphone === doc.userphone);
                        if(absence.length !== 0){
                            const startScheDate = new Date(absence[0].daystart);
                            const startYear = startScheDate.getFullYear().toString();
                            const startMonth = (startScheDate.getMonth()+1).toString();
                            const startDay = startScheDate.getDate().toString();
                            const formattedDateStart = startYear+"/"+startMonth+"/"+startDay;

                            const endScheDate = new Date(absence[0].dayend);
                            const endYear = endScheDate.getFullYear().toString();
                            const endMonth = (endScheDate.getMonth()+1).toString();
                            const endDay = endScheDate.getDate().toString();
                            const formattedDateEnd = endYear+"/"+endMonth+"/"+endDay;

                            period = formattedDateStart+" ~ "+formattedDateEnd;
                            title = absence[0].title;
                        }

                        return (
                            <div className="body_area_mobile" key={doc.id}>
                                <div className="name">{doc.username}</div>
                                <div className="seat">
                                    <div className="seatno">{doc.seatno}</div>
                                </div>
                                <div className="phone">{userPhone}</div>
                                <div className="personalinfo">{userInfo}</div>
                                <div className="schedule">
                                    {absence.length !== 0 ?
                                        <div>{title.length > 15 ? title.slice(0, 15) + '...' : title}</div>
                                    :
                                        <button className="schedule" onClick={()=>specialSchReg("specialSch", doc.userphone, selectedDate)}
                                        >특별일정등록</button> 
                                    }
                                    
                                </div>
                                <div className="term">{period}</div>
                            </div>
                        );
                    })} 
                </div>
            </div>
        </>
    );
}

export default AbsenceMng;